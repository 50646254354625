<template>
	<div class="container">
		<!-- <common-head :title="$t('system.walletRecord')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.walletRecord')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="content">
				<div class="tabBox bgWhite">
					<div class="commonTabtit">
						<ul class="type">
							<!-- <li
								v-for="(item, index) in filterSet"
								:key="index"
								 :class="cashActive == index ? 'active' : ''"
								 @click="handleTabType(index)"
								>
								{{item}}
							</li> -->
							<li @click="handleTabType(0)">
								<div class="txt" :class="cashActive == 0 ? 'active' : ''">{{ $t('pledge.all') }}</div>
								<div class="bottom_xian" :class="cashActive == 0 ? 'active1' : ''"></div>
							</li>
							<li @click="handleTabType(1)">
								<div class="txt" :class="cashActive == 1 ? 'active' : ''">{{ $t('xinzeng.chongbi') }}</div>
								<div class="bottom_xian" :class="cashActive == 1 ? 'active1' : ''"></div>
							</li>
							<li @click="handleTabType(2)">
								<div class="txt" :class="cashActive == 2 ? 'active' : ''">{{ $t('xinzeng.tibi') }}</div>
								<div class="bottom_xian" :class="cashActive == 2 ? 'active1' : ''"></div>
							</li>
							<li @click="handleTabType(3)">
								<div class="txt" :class="cashActive == 3 ? 'active' : ''">{{ $t('system.transfer_in') }}</div>
								<div class="bottom_xian" :class="cashActive == 3 ? 'active1' : ''"></div>
							</li>
							<li @click="handleTabType(4)">
								<div class="txt" :class="cashActive == 4 ? 'active' : ''">{{ $t('system.transfer_out') }}</div>
								<div class="bottom_xian" :class="cashActive == 4 ? 'active1' : ''"></div>
							</li>
						</ul>
					</div>
					
					<div class="bottomDIvStyle">
						<van-list @load="onLoadcashFlow" v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')">
							<van-row class="vanRowBoderStyle" v-for="(item, index) in billList" :key="index">
								<van-col class="bottomDivRowColStyle" span="15">
									<p class="mainFontLighterColor bottomDivRowColStyleP2">{{ item.update_at }}</p>
									<p class="bottomDivRowColStylePS">{{ $t('system.' + item.type) }}</p>
								</van-col>
								<van-col span="9">
									<p class="vanCol4PStyle">
										<span :class="item.amount > 0 ? 'mainColor1' : 'mainColor3'">{{ item.amount }}</span>
									</p>
								</van-col>
							</van-row>
						</van-list>
					</div>
				
					<!-- <tab-bared></tab-bared> -->
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import Loading from '@/components/Loading';
import tabBar from '@/components/tabBar/tabBar.vue';
import commonHead from '@/components/commonHead';

export default {
	name: 'walletRecord',
	components: {
		Loading,
		tabBar,
		commonHead,
	},
	data() {
		return {
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			billPage: 1,

			listData: [],
			assetsDitale: 0,
			rewardData: 0,
			amount: '',
			frozen: '',
			filterSet: [],
			showPicker: false,

			items: [],
			top: 1,
			bottom: 1,
			// ---------20200410[start]----------
			active: 0,
			// 全部''/充币'deposit'/提币'withdraw'/转入'transfer_in'/转出'transfer_out'
			cashtypeSet: ['', 'deposit', 'withdraw', 'transfer_in', 'transfer_out'], //01234
			// cashType: '',
			cashType: '',
			cashActive: 0,
		};
	},

	// filters: {
	// 	amountIsMinus(amount) {
	// 		if (amount.substr(0, 1) == '-') {
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}
	// 	}
	// },

	// watch: {
	// 	take(news, old) {
	// 		this.billPage = 1;
	// 		this.billFinished = false;
	// 		this.billList = [];
	// 	}
	// },

	created() {
		this.billPage = 1;
		// this.returnCashIndex()
	},
	mounted() {
		// this.getFilterSet()
		// this.getFilterSet(), this.getAssets();
		// for (var i = 1; i <= 20; i++) {
		//   this.items.push(i + " - keep walking, be 2 with you.");
		// }
		// this.top = 1;
		// this.bottom = 20;
		//this.getRobbt();
		// this.getSupportedTokens()
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		// 滚动加载列表
		onLoadcashFlow() {
			this.getCashflow();
			// 异步充值记录
			
		},
		
		// 初始化列表-获取列表
		getCashflow() {
			this.$post2('Pay/Api/Index/cashflow', {
				cashType: this.cashType,
				page: this.billPage++
			}).then(res => {
				this.billData = res.list;
				  for (let i = 0; i < this.billData.length; i++) {
					this.billList.push(this.billData[i]);
				  }
				  // console.log(this.shouyiList);
				  // 加载状态结束
				  this.billLoading = false;
				  // 数据全部加载完成
				
				  console.log(this.billData);
				  if (this.billData.length < 1) {
					this.billFinished = true;
				  }
				// for (let i = 0; i < res.list.length; i++) {
				// 	this.billList.push(res.list[i]);
				// }
				// // 加载状态结束
				// this.billLoading = false;
				// // 数据全部加载完成
		
				// if (res.list.length < 1) {
				// 	this.billFinished = true;
				// }
			});
		},
		// returnCashIndex() {
		// 	for (let i = 0; i < this.cashtypeSet.length; i++) {
		// 		if (this.cashtypeSet[i] == this.cashType) {
		// 			this.cashActive = i
		// 		}
		// 	}
		// },
		// 切换类型-全部''/充币'deposit'/提币'withdraw'/转入'transfer_in'/转出'transfer_out'
		// handleTabType(cashType) {
		// 	this.cashType = cashType
		handleTabType(cashActive) {
			this.cashActive = cashActive
			this.cashType = this.cashtypeSet[cashActive]
			
			this.billPage = 1;
			this.billFinished = false;
			this.billList = [];
			this.billData = [];
			this.billLoading = true;
			this.onLoadcashFlow();
		},
		
		// refresh(done) {
		// 	setTimeout(() => {
		// 		this.billPage = 1;
		// 		this.getCashflow();
		// 		// var start = this.top - 1;

		// 		// for (var i = start; i > start - 10; i--) {
		// 		//   this.items.splice(0, 0, i + " - keep walking, be 2 with you.");
		// 		// }
		// 		// this.top = this.top - 10;
		// 		done();
		// 	}, 1500);
		// },
		// infinite(done) {
		// 	this.getCashflow();

		// 	setTimeout(() => {
		// 		var start = this.bottom + 1;
		// 		for (var i = start; i < start + 10; i++) {
		// 			this.items.push(i + ' - keep walking, be 2 with you.');
		// 		}
		// 		this.bottom = this.bottom + 10;

		// 		done();
		// 	}, 1500);
		// },
		//methods中的方法
		// changeConfirm(val, index) {
		// 	this.billPage = 1;
		// 	this.cashType = val; //传值
		// 	console.log(index); //索引
		// 	this.showPicker = false;
		// 	console.log(this.cashType);
		// 	this.getCashflow();
		// 	// 如果最开始的数据源是数组对象，我的做法是先把数据源变成一位数组(用forEach遍历每一项，push到一个新数组中)，用于展示，然后在confirm事件中，拿到索引，根据索引就可以拿到数组中所对应的对象
		// },

		// getFilterSet() {
		// 	this.$post2('Pay/Api/Index/getFilterSet').then(res => {
		// 		console.log(res);
		// 		this.filterSet = res;
		// 	});
		// },
		// 前往互转页面
		// transfer() {
		// 	this.$router.push('transfer');
		// },
		// exchange_tt() {
		// 	this.$router.push('switch');
		// },
		// 获取资产信息
		// getAssets() {
		// 	this.$post2('User/Api/Index/userAsset').then(res => {
		// 		console.log(res);
		// 		// 总资产是 amout + frozen

		// 		let t = res.USDT.amount + res.USDT.frozen;
		// 		this.assetsDitale = t.toFixed(2);
		// 		// let t2 = res.REWARD.amount + res.REWARD.frozen;
		// 		// this.rewardData = t2.toFixed(2);
		// 		// console.log(typeof res.USDT.amount)
		// 		// this.amount =  res.amount
		// 		// + res.frozen
		// 	});
		// },

		// 获取 量化交易
		// getRobbt() {
		// 	this.$post('Robot/Api/Index/getTokenPair').then(res => {
		// 		console.log(res);
		// 	});
		// }
	}
};
</script>

<style scoped lang="less">
	.content {
		margin: 0.2rem 0;
		margin-bottom: 0;
		font-family: PingFangSC-Regular;
	}
.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  padding: 0.15rem 0 .2rem;
  
  .vanCol4PStyle {
    font-size: 0.28rem;
    text-align: right;
    margin-top: 0.25rem;
	span {
		  font-family: Bahnschrift;
	}
  }
  
  .bottomDivRowColStylePS {
    font-size: .28rem;
    line-height: .4rem;
    margin-top: .08rem;
  }
  
  .bottomDivRowColStyleP2 {
    font-size: 0.24rem;
    line-height: .35rem;
  }
  
}
.tabBox {
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	min-height: 91.8vh;
}
.commonTabtit {
		color: #373737;
		font-size: .24rem;
		height: .8rem;
		// border-bottom: 1px solid #EDEDED;
		margin: 0 0.2rem;
		margin-bottom: .2rem;
		.type {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 0.2rem;
			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				.txt {
					padding: 0.1rem 0;
					color: #8E96A3;
					font-size: 0.24rem;
				}
				.bottom_xian {
					width: 0.41rem;
					height: 0.06rem;
					background-color: #FFFFFF;
				}
				// margin-right: .5rem;
				.active {
					font-size: .28rem;
					font-weight: bold;
					color: #333333;
					// border-bottom: 3px solid #EFBA0D;
				}
				.active1 {
					background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
					// border-bottom: 3px solid #EFBA0D;
				}
			}
		}
	}
	.bottomDIvStyle {
		margin-top: 0.4rem;
	}
	.mainColor1 {
		color: #32BF88 !important;
	}
	.mainColor3 {
		color: #F5465D !important;
	}
</style>
