<template>
	<div class="aboutOurs">
		<!-- <common-head :title="title" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="title"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="layoutBox">
				<div class="contact">
					<!-- <div class="tital">{{title}}</div> -->
					<div class="message" v-for="(item,index) in message.split('\n')" :key="index">
						{{item}}
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';

export default {
	name: 'aboutOurs',
	components: {
		commonHead
	},
	data() {
		return {
			title: this.$route.query.title,
			message: this.$route.query.content
		};
	},

	created() {
		this.getAbout();
	},

	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		getAbout() {
			this.$post2('user/api/index/aboutUs', {}).then(res => {
				this.email = res.email;
				this.weixin = res.weixin;
			});
		}
	}
};
</script>

<style lang="less" scoped>
.aboutOurs {
	font-family: PingFangSC-Regular;
	.contact {
		height: calc(100vh - 55px);
		padding: 0.4rem 0.3rem;
		background-color: #FFFFFF;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		.tital {
			font-family: PingFang SC;
			font-size: 0.28rem;
			color: #333;
			font-weight: bold;
		}
		.message {
			// margin-top: 0.3rem;
			color: #333333;
			font-size: 14px;
			// text-indent:28px;
			line-height: 0.6rem;
		}
	}
}

// --------20200411[start]------
.layoutBox {
	margin-top: 0.2rem;
	// padding-top: 0.88rem;
	background-color: #F8F8F8;
}
</style>
