<template>
  <div class="new_list">
    <div class="header" @click="toggle">
		<div class="kuai"></div>
      <div class="title">{{ title }}</div>
    </div>
    <transition name="fade">
      <div v-if="show">
        <ul>
          <li v-for="(item, index) in list" :key="index" class="listQuan">
            <div class="listQuan_1">
              <div class="listQuan_1_left">
                <div class="checkbox">
                  <van-checkbox
                    :name="item"
                    checked-color="#fff"
                    :disabled="item.bot_id == 0"
                  >
                    <template #icon="props">
                      <span
                        class="checkboxIco"
                        :class="{ on: props.checked }"
                      ></span>
                    </template>
                  </van-checkbox>
                </div>
                <div class="content_top" @click="robotStrategy(item)">
                  <div class="name">{{ item.token | namefilter }}</div>
                  <div class="name1">/{{ item.token | namefilter1 }}</div>
                </div>
				<div v-if="type == 'wave_f_long'">
					<div class="csTitle" v-if="item.bot_id != 0 && fangshi == 2">
						<span class="gray2">{{ $t('system.zuoduo') }}</span>
						<!-- <span class="gray2">x{{item.beishu}}</span> -->
					</div>
				</div>
				<div v-else-if="type == 'wave_f_short'">
					<div class="csTitle" v-if="item.bot_id != 0 && fangshi == 2">
						<span class="gray1">{{ $t('system.zuokong') }}</span>
						<!-- <span class="gray1">x{{item.beishu}}</span> -->
					</div>
				</div>
				<div v-else>
					<div class="csTitle" v-if="item.bot_id != 0 && fangshi == 2">
						<span class="gray1" v-if="item.duokong == 2">{{ $t('system.zuokong') }}</span>
						<!-- <span class="gray1" v-if="item.duokong == 2">x{{item.beishu}}</span> -->
						<span class="gray2" v-if="item.duokong == 1">{{ $t('system.zuoduo') }}</span>
						<!-- <span class="gray2" v-if="item.duokong == 1">x{{item.beishu}}</span> -->
					</div>
				</div>
              </div>
<!-- 			  <div class="listQuan_1_center">
			  </div> -->
              <div class="listQuan_1_right" @click="robotStrategy(item)">
				  <div class="right2">{{ item.last }}</div>
                <div
                  class="right1"
                  :class="{
                    red: item.rate.split('%')[0] <= 0,
                    green: item.rate.split('%')[0] > 0,
                  }"
                >
                  {{ item.rate }}
                </div>
              </div>
            </div>
            <div class="listQuan_2" @click="robotStrategy(item)">
              <div class="listQuan_2_item">
                <p class="value">{{ item.num_ }}</p>
                <p class="label">{{ $t("system.Position") }}</p>
              </div>
              <div class="listQuan_2_item">
                <p class="value">{{ item.times }}/{{ item.times_total }}</p>
                <p class="label">{{ $t("system.Replenishmenttimes") }}</p>
              </div>
              <div class="listQuan_2_item">
                <p
                  class="value"
                  :class="{
                    red: item.color === 'red',
                    green: item.color !== 'red',
                  }"
                >
                  {{ item.win }}

                </p>
                <p class="label">{{ $t("system.Floating") }}</p>
              </div>
              <div class="listQuan_2_item">
                <p
                  class="value"
                  :class="{
                    red: item.color === 'red',
                    green: item.color !== 'red',
                  }"
                >
                  {{ item.ratio }}

                </p>
                <p class="label">{{ $t("system.Up") }}</p>
              </div>
            </div>
            <div class="listQuan_3" @click="robotStrategy(item)">
              <div class="btn" style="border-right: 0.5px solid #EFF1F3" @click.stop="setupStrategy(item)">{{$t("system.setup")}}</div>
              <div class="btn" v-if="item.state == 0" @click.stop="start(item)">{{$t("system.start_up")}}</div>
              <div class="btn" v-if="item.state == -1" @click.stop="setupStrategy(item)">{{$t("system.start_up")}}</div>
              <div class="btn red" v-if="item.state == 1" @click.stop="stop(item)">{{$t("system.stop_it")}}</div>
            </div>
             </li>
        </ul>
      </div>
    </transition>
	<van-dialog
		v-model="isShowPoster"
		show-cancel-button
	    :title="$t('system.queshaojiaoyimiyao')"
		@confirm="yes"
		:confirmButtonText="$t('system.lijibangdin')"
		confirmButtonColor="#5899F6"
		:cancelButtonText="$t('system.cancel')"
		cancelButtonColor="#D98181"
		messageAlign="center"
		></van-dialog>
  </div>
</template>

<script>
export default {
  name: "RobotList",
  props: ["list", "title", "exchange", "type","fangshi"],
  data() {
    return {
      show: true,
      isred: 0,
      that: this,
	  isShowPoster: false,
	  exchange_id: null,
	  new_exchange: '',
    };
  },
  filters: {
    namefilter(v) {
      let new_str = v.split("/");
      return new_str[0];
    },
    namefilter1(v) {
      let new_str = v.split("/");
      return new_str[1];
    },
  },
  methods: {
	  yes() {
		  if(this.exchange == 'huobi') {
			  this.exchange_id = 1;
			  // this.exchange = 'HUOBI';
		  }else if(this.exchange == 'bian') {
			  this.exchange_id = 2;
			  // this.exchange = 'BINANCE';
		  }else if(this.exchange == 'okex') {
			  this.exchange_id = 3;
			  // this.exchange = 'OKX';
		  }
		  this.$router.push({
		      path: "/viewAPI2",
		      query: {
		          exchange_id: this.exchange_id,
		          exchange_name: this.exchange,
		      },
		  });
	  },
    // 跳转策略设置页面
    robotStrategy(item) {
      if (item.bot_id) {
        this.$router.push({
          path: "/circularStrategy",
          query: {
            bot_id: item.bot_id,
            homepath: "/quantification",
            robot: item.robot_strategy,
			fangshi: this.fangshi,
			exchange: this.exchange
          },
        });
      } else {
        this.$toast.fail(this.$t("system.PleaseSetTogetInitializationParameters"));
      }
    },
    toggle() {
      this.show = !this.show;
    },
    stop(item) {
      let bot_id = item.bot_id;
      const loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("system.Stoping"),
      });

      if (bot_id) {
        this.$post2("Robot/api/index/toggleBot", {
          bot_id: bot_id,
          state: 0,
		  noToast:true
        })
          .then((res) => {
            loading.clear();
            this.$toast.success(this.$t("system.StopSuccess"));
            this.$emit("change", "stop");
          })
          .catch((e) => {
            loading.clear();
            if(e ='用户燃料费不足'){
              this.$toast.fail(this.$t('system.gasInsufficient'));
              return
            }
            this.$toast.fail(e);
          });
      } else {
        loading.clear();
        this.$toast.fail(this.$t("system.PleaseSetTogetInitializationParameters"));
      }
    },
    setupStrategy(item) {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("system.GetParameters"),
      });

      this.$post2("Robot/Api/Index/getBotId", {
        exchange: this.exchange,
        type: this.type,
        currency: item.token,
      })
        .then((res) => {
          this.bot_id = res.bot_id;
          localStorage.setItem(item.token, res.bot_id);

          loading.clear();

          this.$router.push({
            path: "robotSetup",
            query: {
              bot_id: this.bot_id,
              type: this.type,
              currency: item.token,
			  fangshi: this.fangshi,
              from: "quantification",
              tag: this.tag,
              name: this.name,
              exchange: this.exchange,
              lastpath: this.$route.fullPath,
            },
          });
        })

        .catch((e) => {
          console.log(e);
          loading.clear();
		  // this.$toast.clear(true);
		  if(e == '请先设置交易所密钥') {
			  this.isShowPoster = true;
		  }
        });
    },

    start(item) {
      const loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("system.qidong"),
      });
      let bot_id = item.bot_id;
      if (bot_id) {
        this.$post2("Robot/api/index/toggleBot", {
          bot_id: bot_id,
          state: 1,
		  noToast: true
        })
          .then((res) => {
            loading.clear();
            this.$toast.success(this.$t("system.qidongsuccess"));
            this.$emit("change", "start");
          })
          .catch((e) => {
            loading.clear();
            if(e == '请先设置交易所密钥') {
                this.isShowPoster = true;
            }
            if(e ='用户燃料费不足'){
              this.$toast.fail(this.$t('system.gasInsufficient'));
              return
            }
            this.$toast.fail(e);
          });
      } else {
        loading.clear();
        this.$toast.fail(this.$t("system.PleaseSetTogetInitializationParameters"));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.listQuan {
  .van-checkbox__icon--disabled {
    .checkboxIco {
      background: rgba(111, 121, 137, 0.1);
	  border: 1px solid rgba(111, 121, 137, 0.1);
    }
  }
  .checkboxIco {
    width: 0.33rem;
    height: 0.33rem;
    border: 1px solid #8e8f91;
    display: inline-block;
    border-radius: 100px;
	background: #FFFFFF;
	border: 1px solid #5569FC;
  }
  .checkboxIco.on {
    background: #5569FC;
    border: 1px solid #5569FC;
	position: relative;
  }
  .checkboxIco.on:before,
  .checkboxIco.on::after {
  	content: '';
  	height: 9px;
  	width: 1.5px;
  	border-radius: 10px;
  	display: block;
  	background: #FFFFFF;
  	position: absolute;
  	top: 3.5px;
  	left: 8.5px;
  	transform: rotate(45deg);
  	-ms-transform: rotate(45deg);
  }
  .checkboxIco.on::before {
  	height: 5px;
  	transform: rotate(-45deg);
  	-ms-transform: rotate(-45deg);
  	position: absolute;
  	top: 6px;
  	left: 3.5px;
  }
  .setup {
    padding: 0 0.2rem;
    width: 100%;
    overflow: hidden;
    height: 0.47rem;
    line-height: 0.46rem;
    background: #fdf8ec;
    position: fixed;
    left: 0;
    bottom: 60px;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgb(4, 93, 255);
    span {
      float: right;
    }
  }

  .listButton {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .buttonSetup {
      border: 1px solid #0757f5;
      color: #fff;
      border-radius: 4px;
      background: #0757f5;
      font-size: 0.2rem;
      width: 0.9rem;
      height: 0.38rem;
      margin-bottom: 0.1rem;
      margin-right: 0.1rem;
    }
    .buttonStart {
      border-radius: 4px;
      background: #fff;
      font-size: 0.2rem;
      width: 0.9rem;
      height: 0.38rem;
    }
    .buttonStart,
    .buttonNone,
    .buttonStop {
      border: 1px solid rgb(4, 93, 255);
      color: rgb(4, 93, 255);
      border-radius: 4px;
      background: #fff;
      font-size: 0.2rem;
      width: 0.8rem;
      height: 0.38rem;
    }
    .buttonStop {
      border: 1px solid rgb(217, 105, 105);
      color: rgb(217, 105, 105);
    }

    .van-button--small {
      width: 0.78rem;
      height: 0.42rem;
      line-height: 0.4rem;
      text-align: center;
      margin-right: 0.27rem;
      border: none;
      padding: 0;
      min-width: 0.77rem;
      border-radius: 5px;

      .van-button__text {
        color: #fff;
        font-size: 0.23rem;
        font-family: PingFang SC;
        font-weight: 500;
      }
    }

    .van-button--small:last-child {
      margin-right: 0;
    }
  }
}
</style>
<style lang="less" scoped>
// .fade-enter-active, .fade-leave-active {
//   transition: opacity .5s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
// .data {
//   display: flex;
//   width: 100%;
//   margin-top: 0.16rem;
//   flex-direction: row;
//   text-align: center;
// }
// .change{
//   display: flex;
//   width: 100%;
//   // justify-content: space-between;
//   font-size: 0.26rem;
//   padding-top: 0.1rem;
//   padding-bottom: 0.2rem;
//   flex-direction: row;
//   text-align: center;
// }
// .v{
//   flex: 1;
//     margin-left: 3px;
//     font-size: 0.31rem;
//     font-weight: 500;
//     color: #333;
//   }
// .itemChange{
//   color: #888;
//   flex: 1;
// }
// .content{
//   padding-left: 0.6rem;
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   flex: 1;

// }

.header {
  // margin-bottom: 0.2rem;
  border-radius: 0.15rem;
  // height: 0.8rem;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;
  margin-bottom: 0.1rem;
  .kuai {
  	width: 0.1rem;
  	height: 0.26rem;
  	background-color: #5569FC;
	margin-right: 0.12rem;
  }
}
.title {
  // padding: 0.1rem 0.1rem;
  // border-radius: 5px;
  // margin-left: 0.15rem;
  color: #333333;
  font-size: 0.32rem;
  font-family: PingFang SC;
  width: 50%;
}
.header_right {
  width: 50%;
  font-family: PingFang SC;
  // margin-right: 0.2rem;
  color: #373737;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  .right1 {
    width: 40%;
    text-align: right;
  }
  .right2 {
    width: 65%;
    text-align: center;
  }
}
// .placeholder{
//   margin-top: 20px;
// }
// .spe{
//   display: inline-block;
//   height: 0.25rem;
//   border-right: 0.01rem solid rgb(228, 228, 228);
//   margin: 0 0.4rem;
// }
.quantification {
  .listQuan {
    // padding-bottom: 0.5rem;
    // margin: -0.2rem 0.245rem 0;
    // padding: 0.1rem 0;
    background-color: #ffffff;
    border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
    margin-bottom: 0.2rem;
    &:last-child {
      border-bottom: none;
    }
  }
}
.listQuan_1 {
  display: flex;
  justify-content: space-between;
  margin:  0 0.4rem;
  padding-top: 16px;
  align-items: center;
  .listQuan_1_left {
    display: flex;
    align-items: center;
	// width: 32%;
    // width: 50%;
	.csTitle {
		span {
			color: #90C4FF;
			font-size: 0.24rem;
			transform: scale(0.83);
			padding: 0 .1rem;
			line-height: .36rem;
			background: #F1F7FF;
			border-radius: .04rem;
			display: inline-block;
			margin-left: .1rem;
			&.gray {
				color: #9B9B9B;
				background: #F5F5F5;
			}
			&.gray1 {
				color: #F5465D;
				background: rgba(245, 70, 93, 0.09);
				border-radius: 0.04rem;
			}
			&.gray2 {
				color: #EFBA0D;
				background: rgba(255, 250, 232, 1);
				border-radius: 0.04rem;
			}
		}
	}
    .checkbox {
      margin-left: 0.15rem;
      // margin-top: 0.05rem;
      display: flex;
      align-items: center;
    }
    .content_top {
      margin-left: 0.2rem;
      display: flex;
      align-items: flex-end;
      .name {
		font-family: PingFangSC-Medium;
		font-size: 0.28rem;
        color: #333300;
		font-weight: 600;
      }
      .name1 {
		font-family: PingFangSC-Regular;
        font-size: 0.24rem;
        color: #333300;
      }
      // .name2 {
      //   font-size: 0.41rem;
      //   color: #999;
      // }
    }
  }
  .listQuan_1_center {
	  // margin-left: 0.3rem;
	  // width: 32%;
	  
  }
  .listQuan_1_right {
	  // width: 32%;
    display: flex;
    width: 50%;
    justify-content: space-between;
	align-items: center;
	font-family: PingFangSC-Medium;
	.right2 {
	  font-weight: bold;
	  text-align: center;
	  color: #333333;
	  font-size: 0.28rem;
	}
    .right1 {
      // width: 40%;
      text-align: center;
      border-radius: 4px;
      font-size: 0.24rem;
      padding: 7px 6.5px;
      color: #fff;
    }
    .green {
      border: 1px solid #32BF88;
      background-color: #32BF88;
    }
    .red {
      background-color: #F5465D;
      border: 1px solid #F5465D;
    }
  }
}
.listQuan_2 {
	font-family: PingFangSC-Regular;
  margin: 0 0.4rem;
  padding: 0.3rem 0;
  border-bottom: 0.5px solid #EAECEF;
  justify-content: space-around;
  display: flex;
  .listQuan_2_item {
    text-align: center;
    .label {
      font-size: 14px;
      color: #333333;
    }
    .value {
      color: #333333;
      font-size: 14px;
    }
    .green {
      color: #32BF88;
    }
    .red {
      color: #F5465D;
    }
  }
  .listQuan_2_name {
    color: #373737;
    font-size: 0.36rem;
    font-family: PingFang SC;
    display: flex;
    justify-content: space-between;
    height: 0.8rem;
    align-items: center;
  }
  .listQuan_2_value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.8rem;
    .listQuan_2_value_left {
      display: flex;
      align-items: center;
      .chicang {
        font-size: 0.36rem;
        font-weight: 550;
        margin-right: 0.1rem;
      }
      .bucang {
        font-size: 0.22rem;
        color: #373737;
      }
    }
    .listQuan_2_value_right {
      display: flex;
      align-items: center;
      .fiyin {
        font-size: 0.36rem;
        font-weight: 550;
        margin-left: 0.1rem;
      }
      .fudu {
        font-size: 0.24rem;
      }
      .green {
        color: #07ad91;
      }
      .red {
        color: #cf4e65;
      }
    }
  }
}
.listQuan_3 {
  // height: 1rem;
  display: flex;
  padding: 0.14rem 0.25rem 0.08rem 0.15rem;
  display: flex;
  align-items: center;
  .btn{
    text-align: center;
    flex: 1;
    height: 33.5px;
	line-height: 33.5px;
    // padding-top: 2px;
    font-size: 14px;
    color: #6F7989;
    // font-weight: bold;
  }
  .red {
    color: #F5465D !important
  }
  // .listQuan_3_end {
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 25%;
  //   .img {
  //     width: 0.65rem;
  //     height: 0.65rem;
  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }
}
</style>
