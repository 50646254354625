<template>
	<div class="container">
		<van-nav-bar
		    :title="$t('system.DirectMembershiplist')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="content_box" style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="content">
				<div class="tabBox bgWhite">

					<div class="content-member">
						<van-row class="zhiTitle">
							<van-col span="3" style="font-weight: bold;">{{ $t('system.sequence') }}</van-col>
							<van-col span="6" style="font-weight: bold;">{{ $t('system.accountnumber') }}</van-col>
							<van-col span="3" style="font-weight: bold;">{{ $t('system.activation') }}</van-col>
							<van-col span="3" style="font-weight: bold;">{{ $t('system.Directpush') }}</van-col>
							<van-col span="3" style="font-weight: bold;">{{ $t('system.Team') }}</van-col>
							<van-col span="6" style="font-weight: bold;">{{ $t('redRecode.time') }}</van-col>
						</van-row>
						<van-row class="zhiCent" v-for="(item, index) in userlist" :key="index">
							<van-col span="3">
								<span v-if="index > 9">{{ index + 1 }}</span>
								<span v-else>0{{ index + 1 }}</span>
							</van-col>
							<van-col span="6">{{ item.username }}</van-col>
							<van-col span="3" class="state">
								<span v-if="item.active" class="active">VIP</span>
								<span v-else class="unactive">{{$t('system.inactivatedType')}}</span>
								<!-- <img v-if="item.active" src="../../assets/imgs/jihuo.png" alt="" />
								<img v-else src="../../assets/imgs/un_jihuo.png" alt="" /> -->
							</van-col>
							<van-col span="3">{{ item.direct_count }}</van-col>
							<van-col span="3">{{ item.team_count }}</van-col>
							<van-col span="6" class="left" style="word-break: break-word;">{{ item.create_at }}</van-col>
						</van-row>
					</div>

				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	name: 'DirectMembershiplist',
	data() {
		return {
			userlist: []
		};
	},
	created() {
		this.getInviteList();
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		// 获取列表
		getInviteList() {
			this.$post2('user/api/index/inviteList', {})
				.then(res => {
					this.userlist = res;
				})
				.catch(err => {
					this.$toast(err);
				});
		},
	}
};
</script>

<style scoped lang="less">
.container,
.content_box{
	height: 100%;
}
.content {
	height: calc(100% - 0.2rem);
	margin: 0.2rem 0;
	margin-bottom: 0;
	font-family: PingFangSC-Regular;
}
.tabBox {
	height: 100%;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
}

.content-member {
	padding: 0.4rem 0;
	text-align: center;
	.zhiTitle {
		font-size: 0.24rem;
	}

	.zhiCent {
		font-size: 0.24rem;
		margin-bottom: 0.15rem;
		margin-top: 0.15rem;
		color: #666666;
		display: flex;
		align-items: center;
		.state img {
			width: 0.24rem;
			display: block;
			margin: 0 auto;
		}
		.active {
			color: #2E62FF;
		}
		.unactive {
			color: #6F7989;
		}
	}
	
	.left {
		// text-align: left;
	}
}
</style>
