<template>
	<div class="container">
		<!-- <common-head :title="$t('system.tuoguanxiangqing')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.tuoguanxiangqing')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="content">
				<div class="user_msg">
					<div class="msg">
						<div class="msg-top">
							<div class="msg_left">
								<div class="img"><img :src="teacher_info.avatar" alt="" /></div>
								<div class="name">{{ teacher_info.deposit_name }}</div>
							</div>
							<div class="msg_right">
								<div class="VIP">
									<!-- <div class="img_vip">
			                            <img src="@/assets/imgs/big_vip.png" alt="" v-if="teacher_info.level > 0" />
			                            <img src="@/assets/imgs/small_vip.png" alt="" v-else />
			                        </div> -->
									<div class="vip_number" v-if="teacher_info.level > 0">VIP{{ teacher_info.level }}</div>
									<div class="vip_number" v-else>{{ $t('system.putongVIP') }}</div>
								</div>
								<div class="type">{{ $t('system.fenchengbili') }} {{ teacher_info.brokerage_ratio | fencheng }}%</div>
								<div class="type">{{ teacher_info.exchange && teacher_info.exchange.toUpperCase() }}—{{ type(teacher_info.deposit_type) }}</div>
							</div>
						</div>
						<div class="msg-bottom">
							<div class="msg-bottom-left">
								<div class="Top">{{ teacher_info.me_set_fund | baoliu }}USDT</div>
								<div class="Bottom">{{ $t('system.daidanjiner') }}</div>
							</div>
							<div class="msg-bottom-center">
								<div class="Top">{{ teacher_info.total | baoliu }}USDT</div>
								<div class="Bottom">{{ $t('system.dangqianzijin') }}</div>
							</div>
							<div class="msg-bottom-right">
								<div class="Top">{{ (teacher_info.rate * 100) | baoliu }}%</div>
								<div class="Bottom">{{ $t('system.cangwei') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="user_msg">
					<div class="shuju">
						<div class="list1">
							<div class="top_left">
								<div class="left_num">{{ teacher_info.todayusdt | baoliu }}</div>
								<div class="txt">{{ $t('system.jinrishouyi') }}</div>
							</div>
							<div class="top_right">
								<div class="left_num">{{ teacher_info.totalusdt | baoliu }}</div>
								<div class="txt">{{ $t('system.leijishouyi') }}</div>
							</div>
						</div>
						<div class="list1">
							<div class="top_left">
								<div class="right_num">{{ (teacher_info.threeRate * 100) | baoliu }}%</div>
								<div class="txt">{{ $t('system.jinsantianshouyi') }}</div>
							</div>
							<div class="top_right">
								<div class="right_num">{{ (teacher_info.year_rate * 100) | baoliu }}%</div>
								<div class="txt">{{ $t('system.yuqinianhuashouyilv') }}</div>
							</div>
						</div>
						<div class="list1">
							<div class="top_left">
								<div class="left_num">{{ teacher_info.run_trusteeship_user }}</div>
								<div class="txt">{{ $t('system.zhengzaidaidanrenshu') }}</div>
							</div>
							<div class="top_right">
								<div class="right_num">{{ teacher_info.total_trusteeship_user }}</div>
								<div class="txt">{{ $t('system.zongdaidanrenshu') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="user_msg" style="padding-bottom: 1rem;">
					<div class="list_head">
						<div class="item" @click="qiehuan(0)">
							<div class="top_txt" :class="{ active_txt: list_index == 0 }">{{ $t('system.mrsy') }}</div>
							<div class="active_bottom" :class="{ active_kuai: list_index == 0 }"></div>
						</div>
						<div class="item" @click="qiehuan(1)">
							<div class="top_txt" :class="{ active_txt: list_index == 1 }">{{ $t('system.dangqianchicang') }}</div>
							<div class="active_bottom" :class="{ active_kuai: list_index == 1 }"></div>
						</div>
						<div class="item" @click="qiehuan(2)">
							<div class="top_txt" :class="{ active_txt: list_index == 2 }">{{ $t('system.TransactionRecord') }}</div>
							<div class="active_bottom" :class="{ active_kuai: list_index == 2 }"></div>
						</div>
					</div>
					<van-list
						v-model="billLoading"
						:finished="billFinished"
						:finished-text="$t('cashFlow.NoMore')"
						:loading-text="$t('common.loading')"
						@load="onLoadcashFlow"
						v-if="list_index == 0"
					>
						<van-row class="list" v-for="(item, index) in billList" :key="index">
							<van-col class="list_item">
								<div>{{ item.ymd }}</div>
								<div>+{{ item.USDT | baoliu }} USDT</div>
							</van-col>
						</van-row>
					</van-list>
					<van-list v-if="list_index == 1" class="liebiao1">
						<div v-for="(item, index) in billList1" :key="index" class="item">
							<van-row class="list1_top" type="flex" justify="space-between">
								<van-col class="list1_top_item">
									<span class="item_name">{{ item.currency && item.currency.toUpperCase() | namefilter }}</span>
									<span class="item_name1">/{{ item.currency && item.currency.toUpperCase() | namefilter1 }}</span>
									<div class="item_zuoduo" v-if="item.type == 'wave_f' || item.type == 'wangge_f'">
										<span class="gray1" v-if="zuoduokong(item) == 2">x{{ Beishu(item) }}</span>
										<span class="gray1" v-if="zuoduokong(item) == 2">{{ $t('system.zuokong') }}</span>
										<span class="gray2" v-if="zuoduokong(item) == 1">x{{ Beishu(item) }}</span>
										<span class="gray2" v-if="zuoduokong(item) == 1">{{ $t('system.zuoduo') }}</span>
									</div>
								</van-col>
								<van-col class="list1_top_item">
									<div class="item_right">
										<span class="gray1">{{ item_type(item.type) }}</span>
									</div>
								</van-col>
							</van-row>
							<van-row type="flex" justify="center" class="dixian_kuai"><span class="dixian"></span></van-row>
							<van-row class="list1_bottom" type="flex" justify="space-between">
								<van-col class="listQuan_2" span="6">
									<div class="listQuan_2_item">
										<p class="value">{{ (item.num_ * 1) | baoliu }}</p>
										<p class="label">{{ $t('system.PositionAmount') }}</p>
									</div>
								</van-col>
								<van-col class="listQuan_2" span="6">
									<div class="listQuan_2_item">
										<p class="value">{{ item.avg_price }}</p>
										<p class="label">{{ $t('system.AveragePositionPrice') }}</p>
									</div>
								</van-col>
								<van-col class="listQuan_2" span="6">
									<div class="listQuan_2_item">
										<p
											class="value"
											:class="{
												red: item.num_ * item.win_rate <= 0,
												green: item.num_ * item.win_rate > 0
											}"
										>
											{{ (item.num_ * item.win_rate) | baoliu }}
										</p>
										<p class="label">{{ $t('system.Floating') }}</p>
									</div>
								</van-col>
								<van-col class="listQuan_2" span="6">
									<div class="listQuan_2_item">
										<p
											class="value"
											:class="{
												red: item.win_rate <= 0,
												green: item.win_rate > 0
											}"
										>
											{{ (item.win_rate * 100) | baoliu }}%
										</p>
										<p class="label">{{ $t('system.shouyilv') }}</p>
									</div>
								</van-col>
							</van-row>
						</div>
					</van-list>
					<van-list
						v-if="list_index == 2"
						v-model="billLoading"
						:finished="billFinished"
						:finished-text="$t('cashFlow.NoMore')"
						:loading-text="$t('common.loading')"
						@load="onLoadcashFlow2"
					>
						<div v-for="(item, index) in billList2" :key="index" class="item">
							<van-row class="list1_top" type="flex" justify="space-between">
								<van-col class="list1_top_item">
									<span class="item_name">{{ item.currency && item.currency.toUpperCase() | namefilter }}</span>
									<span class="item_name1">/{{ item.currency && item.currency.toUpperCase() | namefilter1 }}</span>
								</van-col>
								<van-col class="list1_top_item">
									<div class="item_time">{{ item.order_time }}</div>
								</van-col>
							</van-row>
							<van-row type="flex" justify="center" class="dixian_kuai"><span class="dixian"></span></van-row>
							<van-row class="list1_bottom1" type="flex" justify="center">
								<van-col class="listQuan_2" span="5">
									<div class="listQuan_2_item">
										<p class="value">{{ item.num_traded }}</p>
										<p class="label">{{ $t('system.Thenumberoftransactions') }}</p>
									</div>
								</van-col>
								<van-col class="listQuan_2" span="5">
									<div class="listQuan_2_item">
										<p class="value">{{ item.avg_price }}</p>
										<p class="label">{{ $t('system.Averagetransactionprice') }}</p>
									</div>
								</van-col>
							</van-row>
							<van-row class="list1_bottom1" type="flex" justify="center" style="padding-bottom: 0.2rem;">
								<van-col class="listQuan_2" span="5">
									<div class="listQuan_2_item">
										<p class="value">{{ item.times }}</p>
										<p class="label">{{ $t('system.jiaoyileixin') }}</p>
									</div>
								</van-col>
								<van-col class="listQuan_2" span="5">
									<div class="listQuan_2_item">
										<p class="value green">{{ item.profit }}</p>
										<p class="label">{{ $t('system.Profit') }}</p>
									</div>
								</van-col>
							</van-row>
						</div>
					</van-list>
				</div>
				<div class="bottom">
					<div class="button" @click="popup()">{{ $t('system.woyaotuoguan') }}</div>
				</div>
			</div>
			<van-popup v-model="show" round>
				<div class="tan" v-if="have_zhanghao">
					<div v-if="have_amount">
						<div class="input"><input type="number" :placeholder="$t('system.qingshuru')" v-model="number" /></div>
						<div class="tan_no_txt2">
							<div class="left">{{ $t('system.zuidakeyongyue') }}:{{ amount }}USDT</div>
							<div class="right" @click="quanbutuoguan()">{{ $t('system.quanbutuoguan') }}</div>
						</div>
						<div class="tan_no_button">
						    <div class="no" @click="no()">{{$t('system.zanshibuyao')}}</div>
							<div class="yes" @click="tuo()">{{$t('system.querentuoguan')}}</div>
						</div>
					</div>
					<div v-else>
						<div class="tan_no_txt">{{ $t('system.yuerbuzu') }}</div>
						<div class="tan_no_button" @click="tiao_chong()">{{ $t('system.queding') }}</div>
					</div>
				</div>
				<div class="tan" v-else>
					<div class="tan_no_txt">
						{{ $t('system.nidezhanghaohaiweibangding') }}{{ teacher_info.exchange && teacher_info.exchange.toUpperCase() }}{{ $t('system.pingtaizanwufashiyong') }}
					</div>
					<div class="tan_no_button" @click="Tobandin(teacher_info.exchange)">
						{{ $t('system.qubangding') }}{{ teacher_info.exchange && teacher_info.exchange.toUpperCase() }}{{ $t('system.accountnumber') }}
					</div>
				</div>
			</van-popup>
		</div>
		<van-dialog v-model="show0" title="提示" show-cancel-button message="当前带单老师为第三方个人用户，其所有交易操作以及带来的盈亏与平台无关，确认托管后，您的交易账号将同步带单老师的所有操作，交易有风险，请谨慎跟单！" @confirm="yes0"></van-dialog>
	</div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
	components: { commonHead },
	data() {
		return {
			show: false,
			have_zhanghao: true,
			have_amount: true,
			number: null,
			config_id: null,
			teacher_info: {},
			billLoading: false,
			billFinished: false,
			billData: [],
			billData2: [],
			billList: [],
			billList1: [],
			billList2: [],
			billPage: 1,
			amount: 0,
			list_index: 0,
			duokong: 2,
			beishu: 1,
			jiaoyiLX: 'spot',
			assetsDitale: 0,
			show0: false
		};
	},
	filters: {
		fencheng(v) {
			return v * 100;
		},
		baoliu(v) {
			return v ? parseFloat(v).toFixed(2) : '0.00';
		},
		namefilter(v) {
			let new_str = v.split('_');
			return new_str[0];
		},
		namefilter1(v) {
			let new_str = v.split('_');
			return new_str[1];
		}
	},
	created() {
		this.config_id = this.$route.query.config_id;
		console.log(this.config_id);
		this.getTrusteeshipConfigDetail();
		this.getAssets();
	},
	methods: {
		no() {
		    this.show = !this.show;
		},
		onClickLeft() {
			this.$router.back(-1);
		},
		zuoduokong(e) {
			let arr = e.param.trim().split(/\s+/);
			if (e.type == 'wave_f') {
				return arr[7];
			} else if (e.type == 'wangge_f') {
				return arr[9];
			}
		},
		Beishu(e) {
			let arr = e.param.trim().split(/\s+/);
			if (e.type == 'wave_f') {
				return arr[6];
			} else if (e.type == 'wangge_f') {
				return arr[8];
			}
		},
		item_type(e) {
			if (e == 'wangge') {
				// return '马丁批量设置'
				// return this.$t('system.wangge') + this.$t('system.Batchsettings')
				return this.$t('system.setupMarting');
			} else if (e == 'wangge2') {
				// return '底部追踪批量设置'
				// return this.$t('system.wangge2') + this.$t('system.Batchsettings')
				return this.$t('system.wangge2');
			} else if (e == 'wave') {
				return this.$t('system.wangge3');
			} else if (e == 'wangge_m') {
				return this.$t('system.wangge_m');
			} else if (e == 'wave_f') {
				return this.$t('system.wave_f');
			} else if (e == 'wangge_f') {
				return this.$t('system.wangge_f');
			}
		},
		type(v) {
			let a = '';
			if (v == 1) {
				a = this.$t('system.xianhuojiaoyi');
			} else if (v == 2) {
				a = this.$t('system.heyuejiaoyi');
			}
			return a;
		},
		Tobandin(e) {
			if (e == 'huobi') {
				this.$router.push({
					path: '/viewAPI2',
					query: {
						exchange_name: e,
						exchange_id: 1
					}
				});
			} else if (e == 'bian') {
				this.$router.push({
					path: '/viewAPI2',
					query: {
						exchange_name: e,
						exchange_id: 2
					}
				});
			} else if (e == 'okex') {
				this.$router.push({
					path: '/viewAPI2',
					query: {
						exchange_name: e,
						exchange_id: 3
					}
				});
			}
		},
		tiao_chong() {
			this.$router.push('/chargeMoney');
		},
		qiehuan(e) {
			this.list_index = e;
			if (this.list_index == 0) {
				// this.billLoading = true;
				this.billFinished = false;
				this.billPage = 1;
				this.billList = [];
				this.onLoadcashFlow();
			} else if (this.list_index == 1) {
				(this.billList1 = []), this.onLoadcashFlow1();
			} else if (this.list_index == 2) {
				this.billPage = 1;
				(this.billList2 = []), (this.billFinished = false);
				this.onLoadcashFlow2();
			}
		},
		popup() {
			this.show = !this.show;
		},
		tuo() {
			this.show = !this.show;
			this.show0 = true;
		},
		yes0() {
			this.show0 = false;
			this.addTrusteeshipUser();
		},
		quanbutuoguan() {
			this.number = this.amount;
		},
		//开启托管套餐
		addTrusteeshipUser() {
			this.$post2('Deposit/Api/Index/addTrusteeshipUser', {
				config_id: this.config_id,
				escrow_funds: this.number
			})
				.then(res => {
					this.$toast(this.$t('system.success_open'));
					console.log(this.config_id);
					// console.log(res);
					this.$router.push({
						path: '/my_trusteeshio_msg',
						query: {
							config_id: this.config_id
						}
					});
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		//获取托管详情页 - 托管老师信息
		getTrusteeshipConfigDetail() {
			this.$post2('Deposit/Api/Index/getTrusteeshipConfigDetail', {
				config_id: this.config_id
			})
				.then(res => {
					// console.log(res);
					this.teacher_info = res;
					this.getBalance();
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		//获取托管详情页 - 每日收益列表
		getTeacherProfitStatBydayList() {
			this.$post2('Deposit/Api/Index/getTeacherProfitStatBydayList', {
				config_id: this.config_id,
				page: this.billPage++,
				limit: 10
			}).then(res => {
				// console.log(res);
				this.billData = res.list;
				for (let i = 0; i < this.billData.length; i++) {
					this.billList.push(this.billData[i]);
				}
				// console.log(this.shouyiList);
				// 加载状态结束
				this.billLoading = false;
				// 数据全部加载完成

				console.log(this.billData);
				if (this.billData.length < 1) {
					this.billFinished = true;
				}
			});
		},
		//获取当前持仓列表
		getTradeHistory() {
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '处理中...'
				message: this.$t('system.Loading')
			});
			this.$post2('Deposit/api/index/getTradeHistory', {
				config_id: this.config_id,
				exchange: this.teacher_info.exchange
			}).then(res => {
				// console.log(res);
				toast.clear();
				this.billList1 = res;
			});
		},
		//获取历史交易记录列表
		getOrderLogsV2() {
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '处理中...'
				message: this.$t('system.Loading')
			});
			this.$post2('Deposit/api/index/getOrderLogsV2', {
				config_id: this.config_id,
				page: this.billPage++,
				limit: 10
			}).then(res => {
				toast.clear();
				// console.log(res);
				this.billData2 = res.list;
				for (let i = 0; i < this.billData2.length; i++) {
					this.billList2.push(this.billData2[i]);
				}
				// console.log(this.shouyiList);
				// 加载状态结束
				this.billLoading = false;
				// 数据全部加载完成

				console.log(this.billData2);
				if (this.billData2.length < 1) {
					this.billFinished = true;
				}
			});
		},
		// 获取-某一个-交易所的可用余额-传参-(交易所exchange=>1:huobi/2:bian/3:okex/4:uniswap)
		getBalance() {
			if (this.teacher_info.deposit_type == 1) {
				this.jiaoyiLX = 'spot';
			} else if (this.teacher_info.deposit_type == 2) {
				this.jiaoyiLX = 'swap';
			}
			this.$post2('Robot/Api/Index/getBalance', {
				exchange: this.teacher_info.exchange,
				type: this.jiaoyiLX,
				noToast: true
			})
				.then(res => {
					if (res.USDT.amount !== null) {
						this.amount = res.USDT.amount;
						// if (res.usdt.amount <= 0) {
						// 	this.have_amount = false;
						// }
					}
				})
				.catch(e => {
					// console.log("e222: ",e);
					//     this.$toast.clear()
					if (e.indexOf('交易所API KEY') !== -1) {
						this.have_zhanghao = false;
						console.log(e);
					}
				});
		},
		// 获取资产信息
		getAssets() {
		  this.$post2("User/Api/Index/userAsset").then((res) => {
		    console.log(res);
		    // 总资产是 amout + frozen
		
		    let t = res.USDT.amount + res.USDT.frozen;
		    this.assetsDitale = t.toFixed(2);
			if (this.assetsDitale <= 0) {
				this.have_amount = false;
			}
		    // let t2 = res.REWARD.amount + res.REWARD.frozen;
		    // this.rewardData = t2.toFixed(2);
		    // console.log(typeof res.USDT.amount)
		    // this.amount =  res.amount
		    // + res.frozen
		  });
		},
		onLoadcashFlow() {
			this.getTeacherProfitStatBydayList();
		},
		onLoadcashFlow1() {
			this.getTradeHistory();
		},
		onLoadcashFlow2() {
			this.getOrderLogsV2();
		}
	}
};
</script>

<style scoped lang="less">
.container {
	background-color: #f8f8f8;
	height: 100%;
	font-family: PingFangSC-Regular;
	// height: calc(100vh + 110px);
	.content {
		background-color: #f8f8f8;
		padding-bottom: 1rem;
	}
}

.user_msg {
	background-color: #ffffff;
	margin-top: 10px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	// box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	font-family: PingFang SC;
	.msg {
		padding: 0.4rem 0.2rem 0.16rem 0.2rem;
		.msg-top {
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid #e8f1fe;
			padding-bottom: 0.32rem;
			.msg_left {
				// margin-left: 1.34rem;
				.img {
					width: 1.2rem;
					height: 1.2rem;
					img {
						border-radius: 0.5rem;
						width: 100%;
						height: 100%;
					}
				}
			}
			.msg_right {
				margin-left: 0.6rem;
				.name {
					color: #333333;
					font-size: 0.28rem;
					font-weight: 500;
				}
				.VIP {
					display: flex;
					align-items: center;
					.img_vip {
						// width: 0.24rem;
						// height: 0.24rem;
						img {
							width: 0.252399rem;
							height: 0.2218rem;
						}
					}
					.vip_number {
						font-family: 'haose';
						margin-left: 0.1rem;
						font-size: 0.28rem;
						color: #2E62FF;
					}
				}
				.type {
					margin-top: 0.1rem;
					color: #2E62FF;
					background-color: rgba(46, 98, 255, 0.1);
					padding: 0.12rem 0.12rem 0.1rem 0.12rem;
					font-size: 0.24rem;
					transform: scale(0.91);
					text-align: center;
					font-weight: 600;
				}
			}
		}
		.msg-bottom {
			margin-top: 0.16rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.28rem;
			color: #5899f6;
			text-align: center;
			.msg-bottom-left {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 33%;
				padding: 0.1rem 0 0.12rem 0;
				border-right: 1px solid #e8f1fe;
				.Top {
					font-family: PingFangSC-Medium;
					text-align: center;
					color: #333333;
					font-size: 0.32rem;
					font-weight: 600;
				}
				.Bottom {
					text-align: center;
					color: #6f7a8b;
					font-size: 0.24rem;
					font-weight: 600;
				}
			}
			.msg-bottom-center {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 33%;
				padding: 0.1rem 0 0.1rem 0;
				.Top {
					font-family: PingFangSC-Medium;
					text-align: center;
					color: #333333;
					font-size: 0.28rem;
					font-weight: 600;
				}
				.Bottom {
					text-align: center;
					color: #666666;
					font-size: 0.2rem;
					font-weight: 600;
				}
			}
			.msg-bottom-right {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 33%;
				padding: 0.1rem 0 0.1rem 0;
				border-left: 1px solid #e8f1fe;
				.Top {
					font-family: PingFangSC-Medium;
					text-align: center;
					color: #333333;
					font-size: 0.32rem;
					font-weight: 600;
				}
				.Bottom {
					text-align: center;
					color: #6f7a8b;
					font-size: 0.24rem;
					font-weight: 600;
				}
			}
		}
	}
	.shuju {
		padding: 0.32rem 0.64rem 0.12rem 0.64rem;
		.list1 {
			display: flex;
			align-items: center;
			margin-bottom: 0.2rem;
			justify-content: space-between;
			.top_left {
				width: 40%;
				text-align: center;
			}
			.top_right {
				width: 33%;
				text-align: center;
			}
			.txt {
				color: #6f7a8b;
				font-size: 0.24rem;
			}
			.left_num {
				font-family: PingFangSC-Medium;
				color: #333;
				font-size: 0.32rem;
				font-weight: 600;
			}
			.right_num {
				font-family: PingFangSC-Medium;
				color: #32bf88;
				font-size: 0.32rem;
				font-weight: 600;
			}
		}
	}
	.list_head {
		margin: 0 0.2rem;
		padding: 0.3rem 0 0 0;
		display: flex;
		justify-content: space-between;
		border-bottom: 0.02rem solid #d3d9de;
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			.top_txt {
				font-size: 0.28rem;
				color: #8b9fad;
				font-weight: 500;
			}
			.active_bottom {
				margin-top: 0.08rem;
				width: 0.6rem;
				height: 0.06rem;
				background-color: #ffffff;
			}
			.active_txt {
				font-family: PingFangSC-Medium;
				color: #333333;
				font-weight: 600;
			}
			.active_kuai {
				background-color: #2E62FF;
			}
			&:first-child {
				margin-left: 0.56rem;
			}
			&:last-child {
				margin-right: 0.56rem;
			}
		}
	}
	.list {
		margin: 0 0.6rem;
		.list_item {
			padding: 0.4rem 0 0.2rem 0;
			width: 100%;
			border-bottom: 0.01rem solid rgba(211, 217, 222, 0.49);
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 0.28rem;
			color: #333333;
			font-weight: 500;
		}
		// .list_item:last-child {
		// 	padding-bottom: 0.5rem;
		// }
	}
	// .item {
	// 	margin-bottom: 0.2rem;
	// }
	.list1_top {
		padding: 0 0.2rem;
		margin: 0 0.2rem;
		margin-top: 0.39rem;
		background-color: #fcfcfc;
		// border-bottom: 1px solid rgba(88, 153, 246, 0.3);
		border-top-right-radius: 0.16rem;
		border-top-left-radius: 0.16rem;
		.list1_top_item {
			// padding: 0.2rem;
			padding: 10px 0;
			display: flex;
			align-items: center;
			.item_name {
				font-family: PingFangSC-Medium;
				font-size: 0.28rem;
				font-weight: 600;
				color: #333300;
			}
			.item_name1 {
				font-size: 0.24rem;
				color: #6f7a8b;
			}
			.item_time {
				color: #6f7a8b;
				font-size: 12px;
			}
			.item_zuoduo {
				span {
					font-size: 0.24rem;
					transform: scale(0.83);
					padding: 0 0.1rem;
					line-height: 0.36rem;
					background: #f1f7ff;
					border-radius: 0.04rem;
					display: inline-block;
					margin-left: 0.05rem;
					&.gray {
						color: #9b9b9b;
						background: #f5f5f5;
					}
					&.gray1 {
						padding: 0.02rem 0.13rem;
						border: none;
						color: #f5465d;
						background: rgba(245, 70, 93, 0.1);
					}
					&.gray2 {
						padding: 0.02rem 0.13rem;
						border: none;
						color: #32bf88;
						background: rgba(50, 191, 136, 0.09);
					}
				}
			}
			.item_right {
				.gray1 {
					color: #2E62FF;
					font-size: 0.28rem;
					text-align: center;
				}
			}
		}
	}
	.dixian_kuai {
		margin: 0 0.2rem;
		background-color: #fcfcfc;
		.dixian {
			margin: 0 auto;
			width: 6.7rem;
			height: 0.02rem;
			background-color: rgba(88, 153, 246, 0.08);
		}
	}
	.list1_bottom {
		margin: 0 0.2rem;
		padding: 0.2rem 0;
		background-color: #fcfcfc;
		// border-bottom: 1px solid rgba(88, 153, 246, 0.3);
		border-bottom-right-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
		.listQuan_2 {
			// margin: 0.1rem 0.25rem 0.1rem 0.15rem;
			// padding: 12px 0 16px 0;
			// display: flex;
			// justify-content: space-between;
			.listQuan_2_item {
				text-align: center;
				.label {
					font-size: 0.24rem;
					color: #6f7a8b;
				}
				.value {
					font-family: PingFangSC-Medium;
					color: #333333;
					font-size: 14px;
					font-weight: bold;
				}
				.green {
					color: #32bf88;
				}
				.red {
					color: #f5465d;
				}
			}
			.listQuan_2_name {
				color: #373737;
				font-size: 0.36rem;
				font-family: PingFang SC;
				display: flex;
				justify-content: space-between;
				height: 0.8rem;
				align-items: center;
			}
			.listQuan_2_value {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.8rem;
				.listQuan_2_value_left {
					display: flex;
					align-items: center;
					.chicang {
						font-size: 0.36rem;
						font-weight: 550;
						margin-right: 0.1rem;
					}
					.bucang {
						font-size: 0.22rem;
						color: #373737;
					}
				}
				.listQuan_2_value_right {
					display: flex;
					align-items: center;
					.fiyin {
						font-size: 0.36rem;
						font-weight: 550;
						margin-left: 0.1rem;
					}
					.fudu {
						font-size: 0.24rem;
					}
					.green {
						color: #07ad91;
					}
					.red {
						color: #cf4e65;
					}
				}
			}
		}
	}
	.list1_bottom1 {
		margin: 0 0.2rem;
		background-color: #fcfcfc;
		// border-bottom: 1px solid rgba(88, 153, 246, 0.3);
		border-bottom-right-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
		.listQuan_2 {
			margin: 0.2rem 1rem;
			.listQuan_2_item {
				text-align: left;
				.label {
					font-size: 0.24rem;
					color: #6f7a8b;
				}
				.value {
					font-family: PingFangSC-Medium;
					color: #333333;
					font-size: 14px;
					font-weight: 600;
				}
				.green {
					color: #32bf88;
				}
				.red {
					color: #f5465d;
				}
			}
			.listQuan_2_name {
				color: #373737;
				font-size: 0.36rem;
				font-family: PingFang SC;
				display: flex;
				justify-content: space-between;
				height: 0.8rem;
				align-items: center;
			}
			.listQuan_2_value {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.8rem;
				.listQuan_2_value_left {
					display: flex;
					align-items: center;
					.chicang {
						font-size: 0.36rem;
						font-weight: 550;
						margin-right: 0.1rem;
					}
					.bucang {
						font-size: 0.22rem;
						color: #373737;
					}
				}
				.listQuan_2_value_right {
					display: flex;
					align-items: center;
					.fiyin {
						font-size: 0.36rem;
						font-weight: 550;
						margin-left: 0.1rem;
					}
					.fudu {
						font-size: 0.24rem;
					}
					.green {
						color: #07ad91;
					}
					.red {
						color: #cf4e65;
					}
				}
			}
		}
	}
}
.bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	.button {
		margin: 0.14rem 0.3rem 0.14rem 0.28rem;
		width: 6.9rem;
		height: 0.88rem;
		line-height: 0.88rem;
		background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
		color: #FFFFFF;
		font-size: 0.32rem;
		text-align: center;
		border-radius: 0.12rem;
		font-weight: 500;
	}
}
.tan {
	width: 5.6rem;
	height: 2.94rem;
	position: relative;
	.tan_no_txt {
		padding: 0.69rem 0.82rem 0 0.6rem;
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		font-weight: 500;
	}
	.tan_no_txt1 {
		padding: 0.69rem 0.82rem 0 0.72rem;
		color: #333333;
		font-size: 0.24rem;
		text-align: left;
		font-weight: 500;
		color: #333333;
	}
	.tan_no_txt2 {
		margin-top: 0.05rem;
		color: #333333;
		font-size: 0.16rem;
		transform: scale(0.75);
		display: flex;
		justify-content: space-between;
		font-weight: 500;
		text-align: left;
		.right {
			color: #2E62FF;
		}
	}
	.input {
		padding: 0.66rem 0.7rem 0 0.72rem;
		color: #333333;
		font-weight: bold;
		input {
			width: 100%;
			border: none;
			border-bottom: 1px solid #d3d9de;
			padding: 0.04rem 0;
			font-size: 0.32rem;
		}
	}
	.tan_no_button {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		height: 0.8rem;
		line-height: 0.8rem;
		text-align: center;
		border-top: 0.01rem solid #D3D9DE;
		.yes {
			flex: 1;
		    color: #2E62FF;
			font-size: 0.28rem;
			border-right: 0.01rem solid #D3D9DE;
		}
		.no {
			flex: 1;
		    color: #2E62FF;
		}
	}
}
</style>
