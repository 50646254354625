<template>
	<div class="bill-list-item">
		<div class="hd">
			<div class="caption">
				<div class="left">
					<span class="yen">{{ list.currency_pair | name_one}}/{{ list.currency_pair | name_two}}</span>
					<span class="type" :class="list.type == 1 || list.type == 2 ? 'active': 'active1'">{{ typeName(list.type) }}</span>
				</div>
				<span class="date">{{ list.update_time }}</span>
			</div>
		</div>
		<div class="bd">
		<!-- <div class="bd"> -->
			<div class="msg">
				<div class="item1">
					<div class="vlu">{{ list.order_id }}</div>
					<div class="name">{{ $t('system.Orderid') }}</div>
				</div>
				<div class="item2">
					<div class="vlu">{{ list.num_traded }}</div>
					<div class="name">{{ $t('system.Thenumberoftransactions') }}</div>
				</div>

			</div>
			<div class="msg">
				<div class="item1">
					<div class="vlu">{{ list.avg_price }}</div>
					<div class="name">{{ $t('system.Averagetransactionprice') }}</div>
				</div>
				<div class="item2">
					<div class="vlu">{{ list.fee }}</div>
					<div class="name">{{ $t('system.Handlingfee') }}</div>
				</div>
			</div>
			<div class="msg">
				<div class="item1">
					<div class="vlu">{{ list.trade_amount }}</div>
					<div class="name">{{ $t('system.transactionfee') }}</div>
				</div>
				<div class="item2">
					<div class="vlu">{{ list.times }}</div>
					<div class="name">{{ $t('system.Replenishmenttimes') }}</div>
				</div>
			</div>
			<div class="msg">
				<div class="item1">
					<div class="vlu">{{ list.profit }}</div>
					<div class="name">{{ $t('system.Profit') }}</div>
				</div>
			</div>
		</div>
<!-- 		<div class="fd" @click="handleToggle">
			<img :class="showContent ? 'up' : ''" src="@/assets/imgs/icon/icon_dropdown.png" alt="" />
		</div> -->
	</div>
</template>

<script>
	export default {
		name: 'billList',
		props: {
			list: {
				type: Object,
				default: () => {},
			},
			exchange: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				showContent: false,
			}
		},
		filters: {
			name_one(v) {
				return v.split('/')[0]  
			},
			name_two(v) {
				return v.split('/')[1]  
			}
		},
		methods: {
			typeName(type) {
				if (type == '1') {
					// return '买入';
					return this.$t('system.Buyin')
				}
				if (type == '0') {
					// return '卖出';
					return this.$t('system.Sell')
				}
			},
			handleToggle() {
				this.showContent = !this.showContent
			},
		},
	}
</script>

<style scoped lang="less">
	
	.bill-list-item {
		color: #666666;
		font-size: .28rem;
		// background: #F5F5F5;
		background: #FFFFFF;
		border-radius: 0.16rem;
		margin-bottom: 0.25rem;
		.hd {
			margin: 0 .2rem;
			border-bottom: 0.5px solid #D3D9DE;
			.caption {
				padding: 0.2rem 0;
				// height: .4rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left {
					display: flex;
					align-items: center;
					.yen {
						color: #333333;
						font-size: 0.32rem;
						margin-right: 0.28rem;
					}
					.type {
						font-size: .24rem;
						transform: scale(0.83);
					}
					.active {
						padding: 0.03rem 0.08rem;
						background-color: rgba(50, 191, 136, 0.1);
						color: #32BF88;
						border-radius: 0.04rem;
					}
					.active1 {
						padding: 0.03rem 0.08rem;
						background-color: rgba(245, 70, 93, 0.1);
						color: #F5465D;
						border-radius: 0.04rem;
					}
				}
				.date {
					color: #666666;
					font-size: .24rem;
				}
				// margin-bottom: .25rem;
			}
			.specialFont {
				color: #666666;
				font-size: 0.24rem;
				text-transform: uppercase;
			}
			
		}
		.bd {
			// color: #373737;
			// padding: .5rem .2rem  0;
			padding: 0.21rem 0.85rem;
			border-top: 1px solid #F5F5F5;
			.msg {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.2rem;
				text-align: left;
			}
			.item1{
				width: 60%;
				.vlu {
					color: #333333;
					font-size: 0.28rem;
					margin-bottom: 0.02rem;
					font-weight: bold;
				}
				.name {
					color: #666666;
					font-size: 0.24rem;
					// font-weight: bold;
				}
			}
			.item2{
				width: 32%;
				.vlu {
					color: #333333;
					font-size: 0.28rem;
					margin-bottom: 0.02rem;
					font-weight: bold;
				}
				.name {
					color: #666666;
					font-size: 0.24rem;
					// font-weight: bold;
				}
			}
		}
		.fd {
			height: .5rem;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				top: 50%;
				border-top: 1px solid #F5F5F5;
			}
			img {
				width: .24rem;
				height: .24rem;
				display: block;
				// background: #F5F5F5;
				background: #FFFFFF;
				position: relative;
				z-index: 2;
				&.up {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
		
	}
</style>
