<template>
  <div class="chongzhi">
    <nav-bar :title="$t('xinzeng.chongbi')">
      <van-icon name="wap-home-o" slot="right" />
    </nav-bar>
    <div class="chongzhi_box">
      <span>{{ data }}</span>
      <div class="chonzghi_right" @click="show = true">
        {{ $t("xinzeng.xuanzebi") }}
      </div>
    </div>
    <div id="qrcode" ref="qrcode"></div>
    <span class="dizhi">{{ $t("recharge.one") }}</span>
    <div
      class="fuzhi_address btn1"
      :data-clipboard-text="address"
      @click="copy"
    >
      <span class="dizhi_address">{{ address }}</span>
      <img src="../../assets/imgs/fuzhi.png" alt="" />
    </div>
    <span class="zuixiao"
      >{{ $t("recharge.min") }}:{{ deposit_min }}&nbsp;{{ data }}</span
    >

    <van-popup v-model="show" class="addwaltshow_box2" position="bottom">
      <span
        class="box_makewalt"
        @click="choicetoken(item)"
        v-for="item in tokenSet"
        :key="item.id"
      >
        <img :src="item.icon" alt />
        <span>{{ item.token }}</span>
      </span>
      <!-- <span class="box_makewalt box_addwalt"></span> -->
      <span class="line"></span>
      <span class="box_makewalt quxiao" @click="show = false">{{$t('system.cancel')}}</span>
    </van-popup>
  </div>
</template>
<script>
// 复制地址插件
import Clipboard from "clipboard";
// 地址转换二维码插件
import QRCode from "qrcodejs2";

export default {
  name: "coin",
  // components: { Loading },
  data() {
    return {
      show: false,
      data: this.$route.query.token,
      Site: "",
      address: "",
      deposit_min: 0,
      user_token_set: [],

      flag: true,
      tokenSet: [], // 货币列表
      id: "",
      actions: [{ name: "选项一" }, { name: "选项二" }, { name: "选项三" }],
    };
  },
  created() {
    this.getTokenSet();
    this.site();
  },
  methods: {
    choicetoken(e) {
      console.log(e.token);
      this.data = e.token;
      this.site();
      this.id = e.id;
      this.show = false;
    },
    choice(e) {
      this.data = e.token;
      this.site();
      this.id = e.id;
    },
    // 获取数据
    getTokenSet() {
      this.$post2("user/api/index/userAsset", {})
        .then((res) => {
          console.log(res);
          this.tokenSet = res.token_set;
          this.user_token_set = res.user_token_set;
          this.id = res.token_set[0].id;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 复制
    copy() {
      var clipboard = new Clipboard(".btn1");
      clipboard.on("success", (e) => {
        this.$dialog.alert({
          message: this.$t("xinzeng.fuzhichengg"),
        });
        // 释放内存
        clipboard.destroy();
      });
    },
    qrcode(address) {
      // console.log(address)
      new QRCode("qrcode", {
        // width: 150, // 设置宽度，单位像素
        // height: 150, // 设置高度，单位像素
        text: address, // 设置二维码内容或跳转地址
      });
    },
    // 获取地址
    site() {
      this.$post2("pay/api/index/getDepositAddress", {
        token: this.data,
      })
        .then((res) => {
          document.getElementById("qrcode").innerHTML = "";
          this.address = res.address;
          this.deposit_min = res.deposit_min;
          this.$nextTick(() => {
            this.qrcode(res.address);
          });
        })
        .catch((err) => {
          this.$dialog.alert({
            message: this.$t("xinzeng.buzhichi"),
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.addwaltshow_box2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 4.5rem;
  border-radius: 0.1rem 0.1rem 0 0;
  border: none;
  .box_makewalt {
    height: 1.1rem;
    // text-align: center;
    // line-height: 1.1rem;
    color: #5056fd;
    font-size: 0.32rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 0.4rem;
    }
    span {
      margin-left: 0.2rem;
    }
  }
  .box_addwalt {
    border-bottom: none;
  }
  .line {
    height: 0.1rem;
    background: #f6f6f8;
  }
  .quxiao {
    border-bottom: none;
    color: #999999;
  }
}
.chongzhi {
  display: flex;
  flex-direction: column;
  width: 100%;

  .chongzhi_box {
    width: 6.7rem;
    background: #f2f2fe;
    margin: 0 auto;
    margin-top: 0.4rem;
    display: flex;
    border-radius: 5px;
    height: 0.82rem;
    align-items: center;
    justify-content: space-between;
    span {
      margin-left: 0.3rem;
    }
    .chonzghi_right {
      margin-right: 0.3rem;
      font-size: 0.28rem;
    }
  }
  /deep/#qrcode {
    margin: 0 auto;
    margin-top: 1rem;
    width: 2.8rem;
    height: 2.8rem;
    img {
      padding: 5px;
      background: #eceaea;
      width: 2.8rem;
      height: 2.8rem;
    }
  }
  .dizhi {
    margin: 0 auto;
    margin-top: 1.8rem;
    font-size: 0.3rem;
  }
  .fuzhi_address {
    display: flex;
    margin-top: 0.5rem;
    font-size: 0.24rem;
    margin-left: 0.4rem;
    align-items: center;
    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-left: 0.3rem;
    }
  }
  .zuixiao {
    margin: 0 auto;
    margin-top: 1.4rem;
    font-size: 0.28rem;
    // color: #cfcfcf;
  }
}
</style>