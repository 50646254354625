<template>
  <div class="transactionVersion2">
    <div class="title">
      <van-search
        v-model="value"
        shape="round"
        background="#f1f1f1"
        placeholder="请输入搜索关键词"
      />

      <van-tabs
        background="#f1f1f1"
        line-width="0.6rem"
        title-active-color="#35353B"
        title-inactive-color="#B0B0BC"
        color="#E1C364"
        v-model="active"
      >
        <van-tab title=""></van-tab>
        <van-tab class="titleVanTabStyle" title="火必">
          <span :class="tbainsdeSpanStyle1">智能马丁</span>
          <span :class="tbainsdeSpanStyle2">底部追踪</span>

          <van-row class="vanRowStyle">
            <van-col span="3">
              <!-- <img src="" alt="" /> -->
              <div class="vanRowColDivStyle"></div>
              </van-col>
            <van-col @click="routerToDetails" span="18" class="vanRowCol2"
              ><span class="bodySpans1"><strong>BTC/USDT</strong></span
              ><span class="bodySpans2">Bitcoin</span></van-col
            >
            <van-col span="3" class="vanRowCol2"
              ><span class="bodySpans3">运行中</span><span></span
            ></van-col>
          </van-row>
          <van-row class="vanRowStyle">
            <van-col span="3">
              <!-- <img src="" alt="" /> -->
              <div class="vanRowColDivStyle"></div>

              </van-col>
            <van-col span="18" class="vanRowCol2"
              ><span class="bodySpans1"><strong>BTC/USDT</strong></span
              ><span class="bodySpans2">Bitcoin</span></van-col
            >
            <van-col span="3" class="vanRowCol2"
              ><span class="bodySpans3">运行中</span><span></span
            ></van-col>
          </van-row>

          
        </van-tab>
        <van-tab class="titleVanTabStyle" title="币安">币安</van-tab>
        <van-tab title=""></van-tab>
      </van-tabs>
      <!-- <van-search
        background="#4fc08d"
        class="serachInput"
        v-model="value"
        shape="round"
        placeholder="请输入搜索关键词"
      /> -->
    </div>
    <div class="body"></div>
  </div>
</template>

<script>
export default {
  name: "transactionVersion2",
  data() {
    return {
      active: 1,
      changSpanStyle: null,
      // 将变量和样式结合
      tbainsdeSpanStyle1: "tbainsdeSpanStyle1",
      tbainsdeSpanStyle2: "tbainsdeSpanStyle2",

      // 内部tba的内容
    };
  },
  methods: {
    changSpan(e) {
      console.log(e);
    },
    routerToDetails(){
      this.$router.push('quantitativeDetails')
    }
  },
};
</script>

<style scoped>
.vanRowCol2{
  padding-top: 0.1rem;
}
.vanRowColDivStyle{
  background: red;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50px;
}
.vanRowStyle {
  margin: 0.3rem 0rem 0.3rem 0rem;
  padding: 0.2rem 0rem 0rem 0rem;
  border-top: 1px solid #f9f9f9;
}
.bodySpans1 {
  font-size: 0.25rem;
  padding-right: 0.3rem;
}
.bodySpans2 {
  font-size: 0.25rem;

  color: #9e9d9d;
}
.bodySpans3 {
  font-size: 0.25rem;
  color: #3d8051;
}

.tbainsdeSpanStyle1 {
  padding: 0.1rem;
  border-radius: 3px;
  font-size: 0.2rem;
  background: #e4e4ed;
}
.tbainsdeSpanStyle2 {
  padding: 0.1rem;
  border-radius: 3px;
  font-size: 0.2rem;
  /* background:#E4E4ED ; */
  margin-left: 0.2rem;
  color: #b0b0bc;
}
.titleVanTabStyle {
  padding: 0.3rem;
}
.serachInput {
  padding: 0px;
  border-radius: 5px;
  background: red;
}
/* .serachInput {
    height: 2rem;
    padding-bottom: 0.3rem;
} */
.title {
  background: #f1f1f1;
  height: 1.67rem;
  /* padding: 0.1rem 0.3rem 0.3rem 0.3rem; */
}
.transactionVersion2 {
  background: #f1f1f1;
}
</style>