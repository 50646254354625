<template>
	<div class="userLogin">
		<common-head url="/" :title="$t('system.resetPassword')" :is-return="true"></common-head>

		<div class="layoutBox bgWhite">
			<div class="bodyStyleDivStyle">
				<div class="bodyStyleDivStyleP">
					<van-dropdown-menu active-color="#5B54EA">
						<van-dropdown-item v-model="value1" :options="option1" />
					</van-dropdown-menu>
				</div>
				<input type="number" v-model="phone" class="body1StyleInputStyle" :placeholder="$t('system.enterMobileNumber')" />
			</div>

			<div class="comBtnStyle" @click="doLogin" style="margin-top: 4rem;">{{ $t('system.nextStep') }}</div>
		</div>
	</div>
</template>

<script>
import NewTitle from '@/components/title';
import commonHead from '@/components/commonHead';
export default {
	name: 'tesetPassword',
	components: { NewTitle, commonHead },
	data() {
		return {
			sms: '', // 短信验证码
			flag: false, // 获取验证码 是否禁用
			count: 60, // 60s 倒计时
			timer: null, // 倒计时的 定时器
			sid: '', // 验证
			bindPhone: false,
			openid: '',
			email: '',

			phone: '', // 手机号
			password: '', // 密码
			isShowPwd: false, // 是否显示密码
			isSubmit: false,
			value1: '86'
			// loginType: this.$route.query.loginType
		};
	},
	created() {
		this.loginType = this.$route.query.loginType;
	},
	watch: {
		option1(val) {
			if (val && val.length) {
				this.value1 = '86';
			}
		}
	},
	computed: {
		// loginType() {
		//     if (this.$route.query && this.$route.query.loginType) {
		//         return this.$route.query.loginType
		//     }
		//     return null;
		// },
		phoneTypeText() {
			this.phone = '';
			this.password = '';
			if (this.loginType === 'number') {
				return {
					title: '手机号',
					placeholder: '请输入您的手机号'
				};
			}
			return {
				title: '邮箱',
				placeholder: '请输入您的邮箱'
			};
		},
		phoneCode() {
			return this.$t('phoneCode');
		},
		option1() {
			let arr = [];
			if (this.phoneCode) {
				const phoneCodeKey = Object.keys(this.phoneCode);
				phoneCodeKey.forEach(element => {
					if (this.phoneCode[element]) {
						this.phoneCode[element].forEach(item => {
							const data = item.split('+');
							arr.push({
								text: `${data[0]} +${data[1]}`,
								value: data[1]
							});
						});
					}
				});
			}
			return arr;
		}
	},
	methods: {
		switchType(type) {
			this.loginType = type;
		},
		switchLoginType() {
			this.loginType = this.loginType === 'number' ? 'email' : 'number';
		},
		// onClickLeft  点击返回“设置”页面
		onClickLeft() {
			this.$router.push('uSet');
		},
		// 提示
		showAlert(msg) {
			return this.$dialog.alert({
				title: '提示',
				message: msg
			});
		},
		emailReg(email) {
			var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
			if (reg.test(email)) {
				return true;
			}
			return false;
		},
		// 验证
		check() {
			if (this.phone == '' || (this.loginType === 'email' && !this.emailReg(this.phone)) || (this.loginType === 'number' && this.phone.length !== 11)) {
				this.showAlert(`请输入正确的${this.phoneTypeText.title}`);
				return false;
			}

			this.phone.trim(); // 去除一下两端空格
			return true;
		},
		// 注册
		doLogin() {
			if (!this.check()) return;
			if (this.isSubmit) return;
			this.isSubmit = true;
			let url = '/security/index/phonecode';
			let parameter = {
				phone: this.phone,
				nation: this.value1
			};
			this.$post2(url, parameter)
				.then(res => {
					this.$toast.success('验证码发送成功');
					this.isSubmit = false;
					setTimeout(() => {
						this.$router.push({
							path: '/verificationCode',
							query: {
								sid: res.sid,
								account: this.phone,
								loginType: this.loginType,
								type: 'resetpwd',
								code: this.value1,
								date: Date.parse(new Date())
							}
						});
					}, 1000);
				})
				.catch(e => {
					this.isSubmit = false;
					this.showAlert(e);
				});
		}
	}
};
</script>
<style lang="less" scoped>
.userLogin {
	.newTitleTwo {
		background: #fff;
	}

	/* @import "../../assets/css/puliceStyle.css"; */

	input:-webkit-autofill {
		box-shadow: 0 0 0px 1000px white inset !important;
	}

	.pageTitle {
		font-size: 0.48rem;
		font-weight: Bold;
		line-height: 0.78rem;
		margin-bottom: 1.2rem;
	}

	.isShowPwd {
		display: inline-block;
		float: right;
		margin-top: 0.2rem;
		width: 0.34rem;
		height: 0.28rem;
	}

	.isShowPwd img {
		width: 100%;
		height: 100%;
	}

	.linkLIne {
		font-size: 0.26rem;
		color: #333;
		margin-top: 0.64rem;
	}

	.body1StyleInputStyle {
		border: 0;
		font-size: 0.28rem;
		width: 100%;
		line-height: 0.8rem;
		display: block;
	}

	.bodyStyleDivStyleP {
		// margin: 0.56rem 0 0.2rem 0;
	}

	.bodyStyleDivStyle {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding-bottom: 0.1rem;
		// margin-bottom: 1rem;
	}

	.body1Style {
		padding: 0.3rem;
	}

	.bottom1 {
		position: fixed;
		width: 100%;
		bottom: 0.2rem;
	}

	.bottom1 p {
		margin-left: 0.3rem;
		font-size: 0.26rem;
		color: #333;
	}

	.bottom1 .agree {
		color: #a2a2a2;
		font-size: 0.26rem;
		margin-bottom: 0.4rem;
	}

	.bottom1 .agree a {
		color: #000;
	}
	.zh-btn-type {
		text-align: right;
		padding-top: 10px;
		font-size: 0.28rem;
		color: #ff4b04;
	}
}
</style>

<style scoped lang="less">
	.layoutBox {
		padding: .3rem;
		height: calc(100vh - 1.76rem);
	}
</style>