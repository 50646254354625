<template>
	<div class="container">
		<!-- 工具 -->
		<common-head url="/newHome" :title="$t('system.tool')" :is-return="true"></common-head>
		
		<div class="layoutBox bgWhite">
			<div class="list-flex-box">
				<icon-list :label="$t('xinzeng.chongbi')" linkUrl="/chargeMoney">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_charge.png" alt="" />
				</icon-list>
				<icon-list :label="$t('xinzeng.tibi')" linkUrl="/withdrawMoney">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_carry.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.invitefriends')" linkUrl="/sharePage">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_invitation.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.myteam')" linkUrl="/community2">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_team.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.TransactionRecord')" linkUrl="/recordsORjournal" :queryParams="{tab:0}">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_record.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.tool')" linkUrl="/">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_tool.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.Securitycenter')" linkUrl="/securityCenter">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_security.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.Helpcenter')" linkUrl="/">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_help.png" alt="" />
				</icon-list>
				<icon-list :label="$t('system.aboutus')" linkUrl="/aboutOurs">
					<img slot="icon" src="../../assets/imgs/icon/my_icon_about.png" alt="" />
				</icon-list>
			</div>
		</div>
	</div>
</template>

<script>
	import commonHead from '@/components/commonHead';
	import iconList from './components/iconList';
	export default {
		name: 'userTool',
		components: {
			  commonHead,
			  iconList,
		},
	}
</script>

<style scoped lang="less">
	.layoutBox {
		height: calc(100vh - 1.76rem);
		padding: 0.25rem .3rem;
	}
	.list-flex-box {
		display: flex;
		flex-wrap: wrap;
	}
</style>
