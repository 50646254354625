<template>
	<div class="container">
		<!-- 设置交易密码 -->
		<!-- <common-head :title="$t('system.setTransferPass')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.setTransferPass')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="layoutBox bgWhite body1Style">
				<p class="body1StylepFont" style="margin-bottom: .5rem;">
					<span class="mainFontLightColor">{{ $t('system.codeHasSend') }}</span>
					<strong class="mainFontColor" v-if="vcode_method == 'phone'">+{{ nation }} {{ phone }}</strong>
					<strong class="mainFontColor" v-else-if="vcode_method == 'email'">{{ email }}</strong>
				</p>
			
				<form-list>
					<div class="bd" slot="contslot">
						<input type="number" class="inp" v-model="inputCode" :placeholder="$t('login.PleaseEnterVerificationCode')" />
						<div class="button" @click="onSendCode">
							<span v-if="codeSending" class="mainColor1">({{ theTime }})</span>
							<span v-else class="mainColor1">{{ $t('sPhone.getCode') }}</span>
						</div>
					</div>
				</form-list>
				<form-list :label="$t('fahongbao.PleaseEnterTradingPassword')">
					<div class="bd" slot="contslot">
						<input type="password" class="inp" maxlength="6" @input="paypwd=paypwd.replace(/[^\d]/g,'')" v-model="paypwd" :placeholder="$t('fahongbao.SixPassword')" />
					</div>
				</form-list>
				<form-list :label="$t('sPwd.pleaseAffirmPassword')">
					<div class="bd" slot="contslot">
						<input type="password" class="inp" maxlength="6" @input="paypwd2=paypwd2.replace(/[^\d]/g,'')" v-model="paypwd2" :placeholder="$t('sPwd.pleaseAffirmPassword')" />
					</div>
				</form-list>
				
				<div class="comBtnStyle" @click="bindPwd" style="margin-top: 5rem;">{{ $t('sPwd.setPassword') }}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import formList from '../../components/formList';
export default {
	name: 'setThePassword',
	components: {
		formList,
	},
	data() {
		return {
			value: '',
			phone: '', //  用户手机号
			nation: '', // 国家区号
			// popupShow: false,
			// popupShow2: false,
			// popupShow3: false,
			showKeyboard: false,
			exchange_name: '',
			email: '',
			inputCode: '',
			exchange_id: 0,
			article_id: 0,
			list2: {},
			flag: false,
			ifhave: false,
			theTime: 60,
			vcode_method: '',
			show: false,
			paypwd: '',
			codeSending: false,
			paypwd2: '',
			back_url: '/securityCenter'
		};
	},
	created() {
		this.exchange_name = this.$route.query.exchange_name;
		this.exchange_id = this.$route.query.exchange_id;
		// this.getKey();
		this.initData();
		// let a = this.$route.query.homepath;
		// if(a){
		// 	this.back_url = a
		// }
		// this.getKey();
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		initData() {
			this.$post2('user/api/index/getMetaInfo', {})
				.then(res => {
					this.vcode_method = res.vcode_method;
                    this.email = res.email;
					this.phone = res.phone;
					this.nation = res.nation;
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		bindPwd() {
			if (!this.check()) {
				return;
			}
			this.doSetpwd();
		},
		// 设置交易密码
		doSetpwd() {
			this.$post2('/user/api/index/settradepwd', {
				// password: this.loginpwd,
				tradepwd: this.$SHA256(this.paypwd),
				code: this.inputCode,
				sid: this.sid,
			}).then(res => {
				console.log(res);
				this.$toast(this.$t('sPay.TransactionPasswordChangedSuccessfully'));
				this.$router.go(-1);
			});
		},

		// 获取验证码
		onSendCode() {
			if (this.codeSending) {
				return;
			}
			let url = 'security/user/authsmscode';
			// if (this.vcode_method == 'email') {
			// 	url = 'security/user/authemailcode';
			// }
			this.theTime = 60;
			this.codeSending = true;
			this.doCountDown();
			this.$post2(url, {
				op: 'opsecret'
			}).then(res => {
				console.log(res);
				this.sid = res.sid;
			});
		},
		// 又改成 一次定时器了 写的真烂...
		doCountDown() {
			if (this.theTime > 1) {
				this.theTime--;
				this.codeTimer = setTimeout(() => {
					this.doCountDown();
				}, 1000);
			} else {
				this.codeSending = false;
			}
		},
		check() {
			if (this.inputCode == '') {
				this.$toast(this.$t('sPay.PleaseEnterCorrectVerificationCode'))
				return false;
			}
			// if (this.loginpwd == "") {
			//   this.showAlert("请输入正确的登录密码");
			//   return false;
			// }
			if (this.paypwd == '') {
				this.$toast(this.$t('sPay.PleaseEnterCorrectTransactionPassword'));
				return false;
			}
			if (this.paypwd !== this.paypwd2) {
				this.$toast(this.$t('sPay.twoPasswordsDoNotMatch'));
				return false;
			}

			return true;
		},
		onClickRight() {
			this.$router.push({
				path: 'viewArticle',
				query: {
					id: this.article_id
				}
			});
		},
		// onClickLeft() {
		//     this.$router.push("/uSet");
		// },
		onCancel() {
			Toast('取消');
		},
		btnChange() {
			this.show = true;
		}
	}
};
</script>

<style scoped lang="less">
.body1StyleSPan {
	color: #ff4b04;
}

.body1StylepFont {
	// margin-top: 0.3rem;
	font-size: 0.28rem;
	// padding-top: .5rem;
}

/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
	border: 0px;
	width: 60%;
}

.bodyStyleDivStyleP {
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
}

.bodyStyleDivStyle {
	border-bottom: 1px solid #fbfbfd;
	padding-bottom: 0.3rem;
}

.body1Style {
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0.3rem 0.4rem;
	font-family: PingFangSC-Regular;
	min-height:91.8vh;
}

.bottom1 {
	margin-top: 2rem;
}

.whole {
	background: #ffffff;
}
// ----------20200411[start]------
.layoutBox {
	// height: calc(100vh - 0.88rem);
	height: 100vh;
	// padding: 0.1rem 0.3rem;
}

.button {
	border: none !important;
	font-size: 0.24rem;
}
</style>
