<template>
  <div class="fdStyle">
    <!-- <h3>{{msg}}</h3> -->
    <van-nav-bar
      :title="$t('xinzeng.exchange_tt')"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 
        <div class="sonOfTiBi">
                    <div> -->
    <ul class="ulList">
      <!-- <li class="liStyle">
        <div class="inputPapaStyle">
          <p>接收账号</p>
          <input
            v-model="dataForm.username"
            style="width: 90%"
            placeholder="接收账号"
            class="inputStyle"
            type="text"
          />
          <img
            style="width: 0.6rem"
            src="../../assets/imgs/tiBi/saomiao.png"
            alt=""
            @click="scanQr"
          />
        </div>
      </li> -->
      <li class="liStyle">
        <p>{{ $t("system.switch_pack") }}</p>

        <div class="radio_group">
          <van-radio-group v-model="dataForm.type">
            <van-radio
              class="radio_item"
              v-for="(item, index) of itemList"
              :key="index"
              :name="item.type"
            >
              {{ item.desc }}
            </van-radio>
          </van-radio-group>
        </div>
      </li>

      <li class="liStyle">
        <div class="inputPapaStyle">
          <p>交易密码</p>
          <input
            v-model="dataForm.tradepwd"
            type="password"
            style="width: 60%"
            placeholder="请输入交易密码"
            class="inputStyle"
          />
        </div>
      </li>

      <li class="liStyle">
        <div class="inputPapaStyle">
          <p>验证码</p>
          <input
            style="width: 60%"
            v-model="dataForm.code"
            placeholder="请输入验证码"
            class="inputStyle"
            type="text"
          />

          <span class="inputSpan" @click="verificationCode"
            >获取验证码 <span v-if="codeSending">({{ theTime }})</span>
          </span>
        </div>
      </li>

      <li class="liStyle">
        <van-row type="flex" justify="left">
          <van-col span="12">
            <div class="btnDivStyle">
              <van-button class="btnStyle" @click="reset()" type="primary"
                >重置
              </van-button>
            </div>
          </van-col>
          <van-col span="12">
            <div class="btnDivStyle">
              <van-button
                class="btnStyle"
                @click="confirmSwitch()"
                type="primary"
                >确认
              </van-button>
            </div>
          </van-col>
        </van-row>
      </li>
    </ul>
  </div>
  <!-- </div> -->

  <!-- </div> -->
</template>

<script>
export default {
  name: "transfer",
  data() {
    return {
      isVerificationShow1: false,
      info: "",
      itemList: [],

      type: "1",
      inputValue: "",
      inputCode: "",
      haveValue: false,
      haveCode: false,
      transfer_min: 10,
      sid: "", //session id
      nation: "86", //country code
      userInput: false,
      errorInputShow: false,
      codeSending: false,
      theTime: 60,
      total: 0,
      transfer_min: 0,
      fee: 2,
      plus: 0,
      dataForm: {
        tradepwd: "",
        sid: "",
        code: "",
        type: "",
        txid: "",
      },
    };
  },
  mounted() {
    this.prepareSwitch();
  },
  created() {
    if (window.plus) {
      this.plusReady();
    } else {
      document.addEventListener("plusready", this.plusReady, false); //
    }
  },
  methods: {
    plusReady() {
      var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
    },

    getImage() {
      let cmr = plus.camera.getCamera(); // 获取摄像头对象
      let res = cmr.supportedImageResolutions[0]; // 字符串数组，摄像头支持的拍照分辨率
      let fmt = cmr.supportedImageFormats[0]; // 字符串数组，摄像头支持的拍照文件格式
      console.log("Resolution :" + res + ", Format: " + fmt);
      cmr.captureImage(
        (path) => {
          alert("调用成功: " + path);
        },
        (error) => {
          // 拍照操作失败的回调函数
          alert("调用失败: " + error.message);
        },
        { resolution: res, format: fmt } // 摄像头拍照参数
      );
    },

    reset() {
      this.prepareSwitch();
    },
    setArrival() {
      let v = this.dataForm.amount - this.dataForm.fee;
      if (v > 0) {
        this.dataForm.arrival = v.toFixed(2);
      }
    },
    fullWithdraw() {
      this.dataForm.amount = this.total;
      this.setArrival();
    },
    // 获取验证码
    verificationCode() {
      this.haveValue = false;
      if (this.codeSending) {
        return;
      }

      this.theTime = 60;
      this.codeSending = true;
      this.doCountDown();

      this.$post2("Security/User/authsmscodeV2", {})
        .then((res) => {
          this.dataForm.sid = res.sid;
          console.log();
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
    doCountDown() {
      if (this.theTime > 1) {
        this.theTime--;
        this.codeTimer = setTimeout(() => {
          this.doCountDown();
        }, 1000);
      } else {
        this.codeSending = false;
        this.haveValue = true;
      }
    },

    // 申请提现
    prepareSwitch() {
      this.$post2("Pay/Api/Index/prepareSwitchConfig", {})
        .then((res) => {
          console.log(res);
          this.itemList = res.list;
          this.dataForm.txid = res.txid;
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
    confirmSwitch() {
      let form = this.dataForm;

      form.tradepwd = this.$SHA256(form.tradepwd);
      this.$post2("Pay/Api/Index/directConfirmSwitch", form)
        .then((res) => {
          console.log(res);
          this.dataForm.tradepwd = "";
          this.$toast.success("转账成功");
        })
        .catch((e) => {
          this.dataForm.tradepwd = "";
          this.$toast.fail(e);
        });
    },
    onClickLeft() {
      this.$router.push("assets");
    },
    routerToQueRen() {
      this.$router.push("");
    },
  },
};
</script>

<style scoped>
.inputSpan {
  font-size: 0.3rem;
  color: #ecbb34;
  padding-top: 0.2rem;
}
.radio_item {
  font-size: 0.3rem;
  padding: 0.2rem;
}
/* .inputSpan {
  font-size: 0.2rem;
  color: #ECBB34;
} */
.fdStyle {
  background: #f1f1f1;
  height: 100%;
  /* padding: 0.3rem; */
}

.van-ellipsis {
  color: #ffffff !important;
  /* background: green; */
}
.van-nav-bar {
  background: #ecbb34;
}
.van-icon {
  color: #ffffff;
}

.radio_group {
  padding-top: 20px;
}
.radio_group > radio {
  margin-top: 5px;
}
.endList {
  margin-top: 2.3rem;
}
.btnDivStyle {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  text-align: center;
}
.btnStyle {
  border: 0px;
  border-radius: 5px;
  margin: 0.3rem;
  width: 90%;
  background: #0a903b;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  /* margin-left: 0.4rem;
  margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  /* margin-left: 0.4rem;
  margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}

.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}

/* .van-ellipsis {
  background: 3d8051;
} */

.ulList {
  /* margin: 0.3rem; */
  padding: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}

.inputPapaStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #f2f2f2;
}
.inputPapaStyle radio {
  padding: 2rem;
}

.inputStyle {
  border: 0px;
  /* border-top: 0px;
  border-left: 0px;
  border-right: 0px; */
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}
</style>