<template>
    <div class="container">
        <!-- <common-head :title="$t('system.im_teacher')" :is-return="true"><div slot="right" class="head_right" @click="routerTo('/become_teacher')">{{$t('system.add_lianghua')}}</div></common-head> -->
		<van-nav-bar
		    :title="$t('system.im_teacher')"
		    left-arrow
		    @click-left="onClickLeft"
			@click-right="routerTo('/become_teacher')"
			:right-text="$t('system.add_lianghua')"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="content">
			    <div class="user_msg">
			        <div class="content-top">
			            <div class="all_cout">
			                <div class="con_left">{{$t('system.ljyjsr')}}</div>
			                <div class="con_right">{{ total_brokerage | baoliu }} USDT</div>
			            </div>
			        </div>
			    </div>
			    <van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow">
			        <van-row class="user_msg" v-for="(item, index) in billList" :key="index" @click="routerTo1(item)">
			            <van-col class="content1" span="24">
			                <div class="list">
			                    <div class="con_left">{{$t('system.xianshimingcheng')}}</div>
			                    <div class="con_right">{{ item.deposit_name }}</div>
			                </div>
			                <div class="list">
			                    <div class="con_left">{{$t('system.xuanzhejiaoyisuo')}}</div>
			                    <div class="con_right1">{{ item.exchange.toUpperCase() }}</div>
			                </div>
			                <div class="list">
			                    <div class="con_left">{{$t('system.jiaoyifangshi')}}</div>
			                    <div class="con_right">{{ item.deposit_type_name }}</div>
			                </div>
			                <div class="list">
			                    <div class="con_left">{{$t('system.fenchengbili')}}</div>
			                    <div class="con_right">{{ item.brokerage_ratio | fencheng }}%</div>
			                </div>
			                <div class="list">
			                    <div class="con_left">{{$t('system.zhanghuzongzichan')}}</div>
			                    <div class="con_right1">
			                        <div class="left">{{ item.me_set_fund }} USDT</div>
			                        <div class="right right-shenhe" v-if="item.state == 0">{{$t('system.weikaiqi')}}</div>
			                        <div class="right right-jingxin" v-if="item.state == 1">{{$t('system.jinxinzhong')}}</div>
			                        <div class="right right-shenhe" v-if="item.state == 2">{{$t('system.shenhezhong')}}</div>
			                        <div class="right right-shenhe" v-if="item.state == 3">{{$t('system.yichehui')}}</div>
			                        <div class="right right-shenhe" v-if="item.state == 4">{{$t('system.yijujue')}}</div>
			                    </div>
			                </div>
			                <div class="button">
			                    <div class="but_left" @click.stop="routerTo2(item)">{{$t('system.xiugaixinxi')}}</div>
			                    <div class="but_right1" v-if="item.state == 0" @click.stop="start(item)">{{$t('system.kaiqi')}}</div>
			                    <div class="but_right" v-if="item.state == 1" @click.stop="stop(item)">{{$t('system.tingzhilianghua')}}</div>
			                    <div class="but_right" v-if="item.state == 2" @click.stop="che(item)">{{$t('system.chehuishenhe')}}</div>
			                </div>
			            </van-col>
			        </van-row>
			    </van-list>
			    <div class="bottom" @click="all__stop()"><div class="button">{{$t('system.quanbutingzhi')}}</div></div>
			</div>
			<van-popup v-model="show" round>
			    <div class="tan" v-if="user_info.state == 0">
			        <div class="tan_no_txt1">{{$t('system.quedingyaokaiqilianghuama')}}？</div>
			        <div class="tan_no_button">
			            <div class="yes" @click="yes0()">{{$t('system.queding')}}</div>
			            <div class="no" @click="no()">{{$t('system.zanshibuyao')}}</div>
			        </div>
			    </div>
			    <div class="tan" v-if="user_info.state == 1">
			        <div class="tan_no_txt1">{{$t('system.quedingyaotingzhilianghuama')}}</div>
			        <div class="tan_no_txt2">{{$t('system.tingzhihoubunengjixu')}}</div>
			        <div class="tan_no_button">
			            <div class="yes" @click="yes()">{{$t('system.queding')}}</div>
			            <div class="no" @click="no()">{{$t('system.zanshibuyao')}}</div>
			        </div>
			    </div>
			    <div class="tan" v-if="user_info.state == 2">
			        <div class="tan_no_txt1">{{$t('system.quedingyaochehuishenqingma')}}</div>
			        <div class="tan_no_button">
			            <div class="yes" @click="yes1()">{{$t('system.queding')}}</div>
			            <div class="no" @click="no()">{{$t('system.zanshibuyao')}}</div>
			        </div>
			    </div>
			    <div class="tan" v-if="all_stop">
			        <div class="tan_no_txt1">{{$t('system.quedingyaotingzhiall')}}</div>
			        <div class="tan_no_txt2">{{$t('system.tingzhihoubunengjixu')}}</div>
			        <div class="tan_no_button">
			            <div class="yes" @click="yes2()">{{$t('system.queding')}}</div>
			            <div class="no" @click="no()">{{$t('system.zanshibuyao')}}</div>
			        </div>
			    </div>
			</van-popup>
		</div>
    </div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
    components: { commonHead },
    data() {
        return {
            total_brokerage: '',
            is_shenhe: 0,
            show: false,
            billLoading: false,
            billFinished: false,
            billData: [],
            billList: [],
            billPage: 1,
            user_info: {},
            all_stop: false
        };
    },
    created() {
        this.getTotalBrokerage();
    },
    filters: {
        fencheng(v) {
            return v * 100;
        },
        baoliu(v) {
            return v ? parseFloat(v).toFixed(2) : '0.00';
        }
    },
    methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
        all__stop() {
            this.all_stop = true;
            this.user_info = {};
            this.show = !this.show;
        },
        yes2() {
            this.show = !this.show;
            this.stopOrStartAllTrusteeshipConfig();
        },
        stop(e) {
            this.all_stop = false;
            this.user_info = e;
            this.show = !this.show;
        },
        start(e) {
            this.all_stop = false;
            this.user_info = e;
            this.show = !this.show;
        },
        yes0() {
            this.show = !this.show;
            this.startTrusteeshipConfig();
        },
        yes() {
            this.show = !this.show;
            this.stopTrusteeshipConfig();
        },
        yes1() {
            this.show = !this.show;
            this.cancelApplyTrusteeshipTeacher();
        },
        no() {
            this.show = !this.show;
        },
        che(e) {
            this.all_stop = false;
            this.user_info = e;
            this.show = !this.show;
        },
        routerTo() {
            this.$router.push({
                path: '/become_teacher',
                query: {
                    msg: this.$t('system.add_lianghua')
                }
            });
        },
        routerTo2(e) {
            this.$router.push({
                path: './become_teacher',
                query: {
                    msg: this.$t('system.xiugailianghua'),
                    info: e
                }
            });
        },
        routerTo1(e) {
            this.$router.push({
                path: './lianghua',
                query: {
                    config_id: e.id
                }
            });
        },
        //用户撤回申请
        cancelApplyTrusteeshipTeacher() {
            this.$post2('Deposit/Api/Index/cancelApplyTrusteeshipTeacher', {
                id: this.user_info.id
            })
                .then(res => {
                    // console.log(res);
                    this.$toast(this.$t('system.success_che'));
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //老师停止某个托管
        stopTrusteeshipConfig() {
            this.$post2('Deposit/Api/Index/stopTrusteeshipConfig', {
                config_id: this.user_info.id
            })
                .then(res => {
                    // console.log(res);
                    this.$toast(this.$t('system.success_stop'));
                    this.billLoading = true;
                    this.billFinished = false;
                    this.billData = [];
                    this.billList = [];
                    this.billPage = 1;
                    this.onLoadcashFlow();
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //老师开启某个托管
        startTrusteeshipConfig() {
            this.$post2('Deposit/Api/Index/startTrusteeshipConfig', {
                config_id: this.user_info.id
            })
                .then(res => {
                    // console.log(res);
                    this.$toast(this.$t('system.success_open'));
                    this.billLoading = true;
                    this.billFinished = false;
                    this.billData = [];
                    this.billList = [];
                    this.billPage = 1;
                    this.onLoadcashFlow();
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //老师全部停止或者开启自己的托管套餐
        stopOrStartAllTrusteeshipConfig() {
            this.$post2('Deposit/Api/Index/stopOrStartAllTrusteeshipConfig', {
                state: 0
            })
                .then(res => {
                    // console.log(res);
                    this.$toast(this.$t('system.success_stop'));
                    this.billLoading = true;
                    this.billFinished = false;
                    this.billData = [];
                    this.billList = [];
                    this.billPage = 1;
                    this.all_stop = false;
                    this.onLoadcashFlow();
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //我是老师的列表接口
        getMeTrusteeshipConfigList() {
            this.$post2('Deposit/Api/Index/getMeTrusteeshipConfigList', {
                page: this.billPage++
            })
                .then(res => {
                    if (res != null) {
                        this.billData = res.list;
                    } else {
                        this.billData = [];
                    }
					for (let i = 0; i < this.billData.length; i++) {
						this.billList.push(this.billData[i]);
					}
					// console.log(this.shouyiList);
					// 加载状态结束
					this.billLoading = false;
					// 数据全部加载完成
					console.log(this.billPage);
					if (this.billData.length < 1) {
						this.billFinished = true;
					}
                    // console.log(res);
                })
                .catch(e => {});
        },
        //获取累计佣金收入
        getTotalBrokerage() {
            this.$post2('Deposit/Api/Index/getTotalBrokerage', {}).then(res => {
                // console.log(res);
                this.total_brokerage = res.total_brokerage;
            });
        },
        onLoadcashFlow() {
            this.getMeTrusteeshipConfigList();
            // 异步充值记录
            
        }
    }
};
</script>

<style scoped lang="less">
.container {
    background-color: #f8f8f8;
    height: 100%;
	font-family: PingFangSC-Regular;
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #333333;
        font-size: 14px;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    .content-top {
        margin: 0 0.21rem;
        .all_cout {
            display: flex;
            padding: 0.36rem 0;
			font-family: PingFangSC-Medium;
            .con_left {
				font-family: PingFangSC-Medium;
                color: #333333;
                font-size: 0.28rem;
                font-weight: 600;
            }
            .con_right {
                color: #333333;
                font-size: 0.28rem;
                font-weight: 600;
                margin-left: 0.28rem;
            }
        }
    }
    .content1 {
        padding: 0.12rem 0.2rem 0.38rem 0.2rem;
        .list {
            display: flex;
            align-items: center;
            padding: 0.08rem 0;
            .con_left {
                // width: 30%;
				font-family: PingFangSC-Medium;
                color: #333333;
                font-size: 0.28rem;
                font-weight: 550;
                text-align: left;
            }
            .con_right {
                // width: 70%;
                color: #6F7A8B;
                font-size: 0.28rem;
                margin-left: 0.48rem;
                display: flex;
                .right {
                    margin-left: 1.5rem;
                }
                .right-jingxin {
                    color: #13ad8f;
                }
                .right-shenhe {
                    color: #d98181;
                }
            }
			.con_right1 {
				width: 76%;
				color: #6F7A8B;
				font-size: 0.28rem;
				margin-left: 0.2rem;
				display: flex;
				justify-content: space-between;
				.right {
				    // margin-left: 1.5rem;
				}
				.right-jingxin {
				    color: #32BF88;
					font-size: 0.24rem;
				}
				.right-shenhe {
					font-size: 0.24rem;
				    color: #F5465D;
				}
			}
        }
        .button {
            margin-top: 0.28rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .but_left {
                width: 2.92rem;
                height: 0.8rem;
                line-height: 0.75rem;
                // padding: 0.18rem 0.86rem;
                border-radius: 0.08rem;
                text-align: center;
                color: #2E62FF;
                font-size: 0.32rem;
				border: 0.01rem solid #2E62FF;
            }
            .but_right {
                width: 2.92rem;
                height: 0.8rem;
                line-height: 0.75rem;
                // padding: 0.18rem 0.86rem;
                border-radius: 0.08rem;
                text-align: center;
                font-size: 0.32rem;
				color: #2E62FF;
				border: 0.01rem solid #2E62FF;
            }
            .but_right1 {
				width: 2.92rem;
				height: 0.8rem;
				line-height: 0.75rem;
				// padding: 0.18rem 0.86rem;
				border-radius: 0.08rem;
				text-align: center;
				font-size: 0.32rem;
				color: #2E62FF;
				border: 0.01rem solid #2E62FF;
            }
        }
    }
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0.14rem 0.28rem;
        padding: 0.22rem 2.82rem;
        background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
        color: #FFFFFF;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.08rem;
        font-weight: 500;
    }
}
.tan {
    width: 5.6rem;
    height: 2.94rem;
	position: relative;
    .tan_no_txt1 {
        padding-top: 0.64rem;
        padding-left: 0.2rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.12rem;
        color: #333333;
        font-size: 0.24rem;
        color: #666666;
        text-align: center;
        .right {
            color: #2E62FF;
        }
    }
    .tan_no_button {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
        display: flex;
        justify-content: space-between;
		height: 0.8rem;
		line-height: 0.8rem;
		text-align: center;
		border-top: 0.01rem solid #D3D9DE;
        .yes {
			flex: 1;
            color: #2E62FF;
			font-size: 0.28rem;
			border-right: 0.01rem solid #D3D9DE;
        }
        .no {
			flex: 1;
            color: #2E62FF;
        }
    }
}
</style>
