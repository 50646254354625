<template>
    <!-- recordsORjournal -->
    <div>
        <template v-if="tabActive==0">
            <transactionRecords 
            :tabActive="tabActive" 
            @switchTab="switchTab"
            ></transactionRecords>
        </template>
        <template v-else>
            <journal 
            :tabActive="tabActive" 
            @switchTab="switchTab"
            ></journal>
        </template>
    </div>
</template>
<script>
import transactionRecords from '../transactionRecords/transactionRecords';
import journal from '../journal/journal.vue';
export default {
    data(){
        return{
            tabActive:0,//交易记录0 / 日志1
        }
    },
	components: {
		transactionRecords,
        journal,
	},
    
    created(){
        this.tabActive=this.$route.query.tab;
    },
    methods:{
        // 切换 交易记录/日志
        switchTab(tab){
            this.tabActive=tab
        },
    },
}
</script>