<template>
  <div>
	<!-- 转账 -->
	<!-- <common-head :title="$t('liaotian.transferAccounts')" :is-return="true" @onclickRight="onclickRight">
		<span class="text" slot="text">{{ $t('system.zhuanzhangjilu') }}</span>
	</common-head> -->
	<van-nav-bar
	    :title="$t('liaotian.transferAccounts')"
	    left-arrow
	    @click-left="onClickLeft"
		@click-right="onclickRight"
		:right-text="$t('system.zhuanzhangjilu')"
	    safe-area-inset-top
	    fixed
	    z-index="9999"
	/>
	<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
		<div class="layoutBox">
			<div class="ulList">
				<div class="list-cell" @click="showPicker = true">
					{{ token }}
					<div class="right">
						<span class="mainFontLightColor">{{ $t("system.choosebi") }}</span>
						<i class="icon_arrR" style="margin: 0;"></i>
					</div>
				</div>
			</div>
			<div class="contWhiteBox">
				<div class="list-cell-item">
					<div class="hd">
						<span class="mainFontColor">{{ $t('system.reciveAccount')}}</span>
					</div>
					<div class="bd1">
						<input type="text" class="inp" v-model="dataForm.username" @input="checkAccount" :placeholder="$t('system.reciveAccountName')" />
						<!-- <input type="text" class="inp" :placeholder="$t('system.reciveAccountName')" /> -->
						<!-- <div class="scanBtn" @click="scan"></div> -->
					</div>
				</div>
				<div class="list-cell-item">
					<div class="hd">
						<span class="mainFontColor">{{ $t('myPledge.Number')}}</span>
						<span class="mainFontLighterColor">{{ $t('pledge.usable')}} {{token}} : {{ total }}</span>
					</div>
					<div class="bd1">
						<input type="text" id="Number" class="inp" v-model="dataForm.amount" @input="setArrival" :placeholder="$t('myPledge.Number')" />
						<div class="allBtn mainColor1" @click="fullWithdraw">{{ $t('pledge.all')}}</div>
					</div>
				</div>
				<div class="list-cell-item">
					<div class="hd">
						<span class="mainFontColor">{{ $t('system.Handlingfee')}}（{{token}}）</span>
					</div>
					<div class="bd">
						{{ dataForm.fee }}
					</div>
				</div>
			</div>
			<div class="contWhiteBox">
				<div class="list-cell-item1">
					<div class="hd">
						<span class="mainFontColor">{{ $t('system.Arrivalquantity')}}（{{token}}）</span>
					</div>
					<div class="bd">
						{{ dataForm.arrival }}
					</div>
				</div>
			</div>
			<div class="comTips" style="margin-bottom: 1.4rem;">
				<p>{{ $t('system.zuyi')}}{{transfer_min}}{{token && token.toLowerCase()}},{{$t('system.zhuan_shouxufei')}}{{ fee }}{{$t('system.shouxufei_danwei')}}</p>
			</div>
			<div class="comBtnStyle" v-if="NumberType && usernameType" @click="confirmTransfer()">{{ $t('xinzeng.zhuanz') }}</div>
			<div class="comBtnStyle disabled" v-else>{{ $t('xinzeng.zhuanz') }}</div>
		</div>
		
		<!-- 币种选择 -->
		<van-action-sheet
			  v-model="showPicker"
			  :round="false"
			  :actions="token_set"
			  @select="onSelectPeriod"
			></van-action-sheet>
	</div>
	
  </div>
</template>

<script>
import commonHead from '@/components/commonHead';
export default {
	name: "transfer",
  components: { commonHead },
  data() {
    return {
      isVerificationShow1: false,
      info: "",

      inputValue: "",
      inputCode: "",
      haveValue: false,
      haveCode: false,
      transfer_min: 0,
      sid: "", //session id
      nation: "86", //country code
      userInput: false,
      errorInputShow: false,
      codeSending: false,
      theTime: 60,
      total: 0,
      fee: 2,
	  fee_ratio: 0,
      plus: 0,
      dataForm: {
        fee: 0,
        username: "",
        tradepwd: "",
        sid: "",
        code: "",
        phone: "",
        amount: "",
        arrival: 0,
        txid: "",
        token: "USDT",
        mainchain: "",
      },
	  // 20200411
	  token: "USDT",
	  token_set: [],
	  mainchain_set: [],
	  // 20210415----------
	  showPicker: false,
	  periodChoiceSet: [{ name: 'USDT' }],
	  NumberType: false,
	  usernameType: false,
	  fee_type: '',
    };
  },
  mounted() {
    this.prepareTransfer();
	// -----20200411[start]-----
	this.getSupportedShiftTokens();
	this.setArrival();
	// -----20200411[end]-----
  },
  created() {
    if (window.plus) {
      this.plusReady();
    } else {
      document.addEventListener("plusready", this.plusReady, false); //
    }
  },
  methods: {
	  onclickRight() {
	  		  this.$router.push({
	  			  path: '/walletRecord',
	  			  query: {
	  				  // cashType: 'transfer_in'
	  				  cashType: 'transfer_out'
	  			  }
	  		  })
	  },
	  
	  // 初始化-转账数据
	  prepareTransfer() {
	    this.$post2("Pay/Api/Index/prepareTransferConfig", {
			token: this.token
		})
	      .then((res) => {
	        console.log(res);
	        this.dataForm.fee = res.fee;
	        this.total = res.amount;
	        this.transfer_min = res.transfer_min;
	        this.dataForm.txid = res.txid;
	        this.dataForm.amount = 0;
	        this.dataForm.arrival = 0;
	        this.fee_type = res.fee_type;
	        if (res.fee_type == 'fixed') {
	        	if(this.token == 'USDT') {
	        		this.fee = res.fee + 'u';
	        	}else if(this.token == 'FOW') {
	        		this.fee = res.fee + 'f';
	        	}else if(this.token == 'SFOW') {
	        		this.fee = res.fee + 's';
	        	}
	        } else {
	        	this.dataForm.fee = res.fee_ratio * 100 + '%';
	        	this.fee = res.fee_ratio * 100 + '%';
	        }
			// console.log('dataForm', this.dataForm)
	      })
	      .catch((e) => {
	        this.$toast.fail(e);
	      });
	  },
	  
	  onSelectPeriod(item) {
	  		  this.showPeriodChoice = false;
	  		  this.token = item.name;
	  		  this.showPicker = false;
			  this.dataForm.token = item.name;
			  this.prepareTransfer();
			  // if(item.name == 'FOW') {
			  // 	this.dataForm.mainchain = this.mainchain_set[1];
			  // 	this.getDepositAddress()
			  // }else if(item.name == 'USDT') {
			  // 	this.dataForm.mainchain = this.mainchain_set[0];
			  // 	this.getDepositAddress()
			  // }else if(item.name == 'SFOW') {
			  // 	this.dataForm.mainchain = this.mainchain_set[2];
			  // 	this.getDepositAddress()
			  // }
	  },
	  
	  // ----------20200410[start]----------
	  choicetoken(e) {
	    console.log(e.token);
	    this.token = e.token;
	    this.getDepositAddress();
	    this.id = e.id;
	    this.show = false;
	  },
	  choice(e) {
	    this.token = e.token;
	    this.getDepositAddress();
	    this.id = e.id;
	  },
	  // 获取地址
	  getDepositAddress() {
	    this.$post2("Pay/Api/Index/getDepositAddress", {
	      token: this.token,
	      mainchain: this.dataForm.mainchain,
	    })
	      .then((res) => {
	        this.dataForm.address = res.address;
	      })
	      .catch((err) => {
	        this.$dialog.alert({
	          message: this.$t("xinzeng.buzhichi"),
	        });
	      });
	  },
	  //获取支持的币种
	  getSupportedShiftTokens() {
	    this.$post2("Pay/Api/Index/getSupportedShiftTokens")
	      .then((res) => {
	        this.mainchain_set = res.mainchain_set;
	        // this.token_set = res.token_set;
	        this.dataForm.mainchain = res.mainchain_set[0];
	        console.log(this.dataForm.mainchain);
			res.token_set.forEach(item=> {
				this.token_set.push({ name: item.value })
			})
	        // this.getDepositAddress();
	      })
	      .catch((err) => {
	        this.$toast("请检查网络");
	      });
	  },
	  // ----------20200410[end]----------
    plusReady() {
      var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
    },

    getImage() {
      let cmr = plus.camera.getCamera(); // 获取摄像头对象
      let res = cmr.supportedImageResolutions[0]; // 字符串数组，摄像头支持的拍照分辨率
      let fmt = cmr.supportedImageFormats[0]; // 字符串数组，摄像头支持的拍照文件格式
      console.log("Resolution :" + res + ", Format: " + fmt);
      cmr.captureImage(
        (path) => {
          alert("调用成功: " + path);
        },
        (error) => {
          // 拍照操作失败的回调函数
          alert("调用失败: " + error.message);
        },
        { resolution: res, format: fmt } // 摄像头拍照参数
      );
    },
    scan() {
      //console.log(window.TPUBox);

      this.dataForm.username = this.$scan();
    },

    reset() {
      this.prepareTransfer();
    },
    setArrival() {
		// let v = this.dataForm.amount - this.dataForm.fee;
		// if (v >= 0) {
		//   this.dataForm.arrival = v.toFixed(2);
		// 		this.NumberType = true
		// } else {
		// 		  this.NumberType = false
		// }
		
		let amount = this.dataForm.amount
		let total = parseInt(this.total);
		console.log(amount,total);
		if (amount > total) {
			this.$toast('超出可用余额')
			this.dataForm.amount = total
			amount = total
		}
		
		console.log('dataForm', this.dataForm)
		
		if (this.fee_type == 'fixed') {
			// this.fee = res.fee + 'USDT';
				// 固定费用
				let v = amount - this.dataForm.fee;
				if (v > 0) {
					this.dataForm.arrival = Math.floor(v *100)/100;
					this.NumberType = true
				} else {
					this.dataForm.arrival = 0;
					this.NumberType = false
				}
		} else {
			// this.fee = res.fee_ratio * 100 + '%';
				// 按照提币数量*比例计算
				let v = amount - amount * this.fee_ratio;
				this.dataForm.arrival = Math.floor(v *100)/100;
				let fee2 = amount - this.dataForm.arrival;
				this.dataForm.fee = Math.floor(fee2 *100)/100;
				if (v > 0) {
					this.NumberType = true
				} else {
					this.NumberType = false
				}
		}
		
      
    },
    fullWithdraw() {
      this.dataForm.amount = this.total;
      this.setArrival();
    },
    // 获取验证码
    verificationCode() {
      this.haveValue = false;
      if (this.codeSending) {
        return;
      }

      this.theTime = 60;
      this.codeSending = true;
      this.doCountDown();

      this.$post2("Security/User/authsmscodeV2", {})
        .then((res) => {
          this.dataForm.sid = res.sid;
          console.log();
        })
        .catch((e) => {
          this.$toast.fail(e);
        });
    },
    doCountDown() {
      if (this.theTime > 1) {
        this.theTime--;
        this.codeTimer = setTimeout(() => {
          this.doCountDown();
        }, 1000);
      } else {
        this.codeSending = false;
        this.haveValue = true;
      }
    },

    // 申请提现
    // prepareTransfer() {
    //   this.$post2("Pay/Api/Index/prepareTransferConfig", {})
    //     .then((res) => {
    //       console.log(res);
    //       this.dataForm.fee = res.fee;
    //       this.total = res.amount;
    //       this.transfer_min = res.transfer_min;
    //       this.dataForm.txid = res.txid;
    //       this.dataForm.amount = 0;
    //       this.dataForm.arrival = 0;
    //       if (res.fee_type == "fixed") {
    //         this.fee = res.fee + "USDT";
    //       } else {
    //         this.fee = res.fee_ratio * 100 + "%";
    //       }
    //     })
    //     .catch((e) => {
    //       this.$toast.fail(e);
    //     });
    // },
	
	
	checkAccount() {
		let form = this.dataForm;
		if (form.username == '') {
			this.usernameType = false
		} else {
			this.usernameType = true
		}
	},
	
	// 确认转账
    confirmTransfer() {
		if (Number(this.dataForm.amount) < this.transfer_min) {
			this.$toast(this.$t('system.Minimumwithdrawalamount') + this.transfer_min)
			document.getElementById('Number').focus()
			return
		}
		
		this.$router.push({
			path: 'checkTransferVerifyCode',
			query: {
				dataForm: this.dataForm
			}
		})
		return
		
      let form = this.dataForm;

      form.tradepwd = this.$SHA256(form.tradepwd);
	  
      this.$post2("Pay/Api/Index/directConfirmTransfer", form)
        .then((res) => {
          console.log(res);
          this.dataForm.tradepwd = "";
          this.$toast.success("转账成功");
        })
        .catch((e) => {
          this.dataForm.tradepwd = "";
          this.$toast.fail(e);
        });
    },
    onClickLeft() {
      this.$router.back(-1);
    },
    routerToQueRen() {
      this.$router.push("");
    },
  },
};
</script>

<style scoped lang="less">
.inputSpan {
  font-size: 0.3rem;
  color: #ff4b04;
  padding-top: 0.2rem;
  float: right;
}

.fdStyle {
  background: #f1f1f1;
  height: 100%;
  /* padding: 0.3rem; */
}

.van-ellipsis {
  color: #ffffff !important;
  /* background: green; */
}

// .van-nav-bar {
//   background: #ecbb34;
// }

.van-icon {
  color: #ffffff;
}

.endList {
  margin-top: 2.3rem;
}

.btnDivStyle {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  text-align: center;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

// .van-hairline--bottom {
//   background: #ecbb34;
//   color: #ffffff;
// }

// .van-nav-bar__title {
//   background: #ecbb34;
//   color: #ffffff;
// }

/* .van-ellipsis {
      background: 3d8051;
    } */


.inputPapaStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #f2f2f2;
}

.inputStyle {
  border: 0px;
  /* border-top: 0px;
        border-left: 0px;
        border-right: 0px; */
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.whole {
  /* background: #ffffff; */
}

.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

// ----------20200411[start]-----------
.layoutBox {
	// min-height: calc(100vh - 1.76rem);
	min-height: calc(100vh - .68rem);
	// padding: 0 .3rem .5rem;
	margin: 0.2rem 0.2rem 0 0.2rem;
	font-family: PingFangSC-Regular;
}

.ulList {
	margin-top: 0.2rem;
	// padding: 0 0.3rem;
	.list-cell {
		height: 1.18rem;
		line-height: 1.18rem;
		padding: 0 0.25rem;
		background: #ffffff;
		border-radius: 8px;
		// margin-bottom: 0.1rem;
		position: relative;
		font-size: 0.28rem;
		color: #333333;
		font-weight: 500;
		.left {
			font-weight: 550;
		}
		.right {
			position: absolute;
			z-index: 1;
			right: 0.25rem;
			bottom: 0;
		}
	}
}

.contWhiteBox {
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: 10px;
}
.list-cell-item {
	margin-top: 0.2rem;
	padding: .3rem .25rem 0 .25rem;
	// border-bottom: 1px solid #EDEDED;
	line-height: .45rem;
	&:last-child {
		border-bottom: 0;
	}
	.hd {
		color: #333333;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
	}
	.bd {
		color: #333333;
		font-size: .28rem;
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.bd1 {
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		border-bottom: 0.01rem solid #D3D9DE;
	}
	.inp {
		color: #333333;
		font-size: .28rem;
		height: .45rem;
		display: block;
		width: 100%;
		flex: 1;
		border: 0;
		outline: 0;
	}
	input::-webkit-input-placeholder {
	    /* 修改字体颜色 */
	    color: #999999;
	    /* 修改字号，默认继承input */
	    font-size: 12px;
	}
	.scanBtn {
		width: .45rem;
		height: .45rem;
		background: url(../../assets/imgs/new_color/saoma.png) no-repeat center center;
		background-size: .4rem .4rem;
	}
}
.list-cell-item1 {
	margin: 0 0.2rem;
	padding: 0.16rem 0;
	// border-bottom: 1px solid #EDEDED;
	line-height: .45rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:last-child {
		border-bottom: 0;
	}
	.hd {
		color: #333333;
		font-size: .28rem;
		display: flex;
		justify-content: space-between;
	}
	.bd {
		color: #333333;
		font-size: .28rem;
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.bd1 {
		padding: 0.23rem 0;
		display: flex;
		justify-content: space-between;
		position: relative;
		border-bottom: 0.01rem solid #D3D9DE;
	}
	.inp {
		color: #999999;
		font-size: .24rem;
		height: .45rem;
		display: block;
		width: 100%;
		flex: 1;
		border: 0;
		outline: 0;
	}
	input::-webkit-input-placeholder,
	input:-moz-placeholder,
	input::-moz-placeholder,
	input:-ms-input-placeholder {
		color: #999999;
	}
	.scanBtn {
		width: .45rem;
		height: .45rem;
		background: url(../../assets/imgs/new_color/saoma.png) no-repeat center center;
		background-size: .4rem .4rem;
	}
}
.comTips {
	margin-left: -0.25rem;
	width: 7.5rem;
	color: #6F7A8B;
	font-size: .24rem;
	transform: scale(0.83);
	line-height: .45rem;
}
.comBtnStyle {
	color: #FFFFFF;
	font-size: .32rem;
	text-align: center;
	height: 1rem;
	line-height: 1rem;
	background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
	border-radius: 0.16rem;
}
.mainFontLightColor {
	margin-right: 0.1rem;
	color: #666666 !important;
	font-size: 0.24rem;
}
.icon_arrR {
	width: .2rem;
	height: .2rem;
	border-top: .04rem solid #666;
	border-right: .04rem solid #666;
}
.mainFontColor {
	color: #333333;
	font-size: 0.28rem;
	font-weight: 550;
}
.mainFontLighterColor {
	color: #666666;
	font-size: 0.24rem;
}
.allBtn {
	font-size: 0.24rem;
}
// ----------20200411[end]-----------
</style>
