<template>
    <bill v-if="activeTab==0" @switchTab="switchTab"></bill>
	<billBack v-else @switchTab="switchTab"></billBack>
</template>
<script>
import bill from "./bill";
import billBack from "./bill-back";
export default {
    components:{
        bill,
        billBack
    },
    data(){
        return{
            activeTab:0,//今日账单0 总账单1
        }
    },
    methods:{
        switchTab(tab){
            this.activeTab=tab
        }
    },
}
</script>